import { Box, Container, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import HeroSection from '../components/features/HeroSection';
import IconSection from '../components/features/IconSection';
import InstagramImages from '../components/features/InstagramImages';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { baseProducts } from '../utils/stores/productCodes';

const HomePage = () => {
    const theme = useTheme();
    const heroTiles = baseProducts
        .map((prod) => prod.tags.includes('featureTile') && prod.productCode)
        .filter((res) => res);

    const Title = (props: TypographyProps) => (
        <Typography
            sx={{
                fontSize: { xs: 30, md: 38 },
                mt: { xs: 3, md: 1 },
                color: theme.palette.primary.main,
                lineHeight: '115%',
                letterSpacing: '-1px',
                fontWeight: 400,
                pb: 2,

                textAlign: 'center'
            }}
        >
            {props.children}
        </Typography>
    );

    const Body = (props: TypographyProps) => (
        <Typography
            sx={{
                fontSize: { xs: 16, md: 18 },
                opacity: 0.8,
                fontWeight: 400,
                pb: 2,
                maxWidth: { xs: 0.9, md: 0.75 },
                textAlign: 'center'
            }}
        >
            {props.children}
        </Typography>
    );

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Explore Airlie Beach and the Whitsundays. Book tours and day trips, departing daily from Airlie Beach, including iconic destinations such as Whitehaven Beach, Heart Reef, and the Whitsunday Islands."
                />
                <title>GSL Whitsundays : Tours &amp; Experiences in Airlie Beach and the Whitsundays</title>
            </Helmet>
            <HeroSection productCodes={heroTiles} />
            <Box
                sx={{
                    background: ` -webkit-linear-gradient(rgba(255, 255, 255, 1), ${theme.palette.background.subtle})`,
                    width: 1
                }}
            >
                <Container maxWidth="lg" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Title>Experience the best of Airlie Beach</Title>
                    <Body>
                        Airlie Beach is the gateway to the Whitsundays, with the greatest selection of tours and
                        activities in the region. Operators depart on tours daily, with a range of options including
                        tours to Whitehaven Beach, the Great Barrier Reef, and other amazing experiences in this
                        beautiful region. GSL Whitsundays is a local travel shop, situated on the main street of Airlie
                        Beach. Let&apos;s create the ultimate itinerary for you, with the best value, and most amazing
                        experiences in Airlie Beach.
                    </Body>
                </Container>
            </Box>
            <ProductDisplayGrid backgroundColor tag={'popular'} />
            {/* <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
                <Title variant="h4">Cruise Tours</Title>
                <Typography>
                    Cruise the Whitsundays from Airlie Beach. Choose from a range of tours to the Whitsunday Islands,
                    including Whitehaven Beach. Select from tours with included activities such as snorkeling, lunch,
                    bush-walking, water sports, and more. Most tours are family friendly, and depart regularly from
                    Airlie Beach.
                </Typography>
            </Container>
            <ProductDisplayGrid tag={'cruise'} />
            */}
            <IconSection />
            <InstagramImages backgroundColor />
        </>
    );
};

export default HomePage;

import { Container, Grid, Skeleton } from '@mui/material';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PATH_IDS } from '../../utils/PageRouter';
import IsMountedWrapper from '../../utils/functions/isMountedWrapper';
import { Product } from '../../utils/models/products';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import ProductDisplay from './ProductDisplay';

export default function ProductTemplate() {
    const isMounted = IsMountedWrapper();
    const { [PATH_IDS.PRODUCT_NAME]: productName } = useParams() as any;
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const [tour, setTour] = useState<Product | undefined>(
        productController.products.find((product) => product.searchableName === productName) || undefined
    );

    const updateBooking = () => {
        bookingController.setIsLoading(true, isMounted);
        bookingController.setBooking(
            (prev) => ({
                ...prev,
                entity: productController.product?.entity,
                tour: {
                    ...prev.tour,
                    productCode: productController.product?.productCode,
                    productName: productController.product?.name
                },
                quantities:
                    productController.product &&
                    productController.product.priceOptions.map((option) => ({ ...option, value: 0 })),
                isPackage: productController.product?.allowPackage,
                packageProductNames:
                    productController.product?.allowPackage && productController?.product?.packageProductNames
            }),
            isMounted
        );
        bookingController.setIsLoading(false, isMounted);
    };

    const updatePackageBooking = () => {
        productController.product?.allowPackage &&
            bookingController.packageBooking.setData(
                (prev) => ({
                    ...prev,
                    entity: productController.product.packageEntity,
                    tour: {
                        ...prev.tour,
                        productCode: productController.product.packageCode,
                        productName: productController?.packageProducts.find((p) => p.productCode === tour.packageCode)
                            ?.name
                    }
                }),
                isMounted
            );
    };

    useEffect(() => {
        productController.setProduct(tour, isMounted);
    }, [tour]);

    useEffect(() => {
        setTour(productController.products.find((product) => product.searchableName === productName));
    }, [productController.products, productName]);

    useEffect(() => {
        updateBooking();
        updatePackageBooking();
        productController.product?.allowPackage &&
            productController.packageProduct.data?.packageCode !== tour?.packageCode &&
            productController.packageProduct.setData(
                productController.packageProducts.find((pack) => pack?.productCode === tour?.packageCode),
                isMounted
            );
    }, [productController.product?.productCode]);

    useEffect(() => {
        updatePackageBooking();
        productController.product?.allowPackage &&
            productController.packageProduct.data?.packageCode !== tour?.packageCode &&
            productController.packageProduct.setData(
                (prev) => ({
                    ...prev,
                    ...productController.packageProducts.find((pack) => pack?.productCode === tour?.packageCode)
                }),
                isMounted
            );
    }, [productController.packageProducts]);

    useEffect(() => {
        bookingController.setBooking(
            (prev) => ({
                ...prev,
                tour: {
                    ...prev.tour,
                    pickUpLocation: productController.product?.pickupList?.[0]?.locationName
                }
            }),
            isMounted
        );
    }, [productController.product?.pickupList]);

    useEffect(() => {
        productController.product?.allowPackage &&
            bookingController.packageBooking.setData(
                (prev) => ({
                    ...prev,
                    tour: {
                        ...prev.tour,
                        pickUpLocation: productController.packageProduct?.data?.pickupList?.[0]?.locationName
                    }
                }),
                isMounted
            );
    }, [productController.packageProduct?.data?.pickupList]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {tour ? (
                <ProductDisplay tour={tour} />
            ) : (
                <Container maxWidth="lg" style={{ marginBottom: 60 }}>
                    <Grid container direction="column">
                        <Skeleton
                            variant="rectangular"
                            width={300}
                            height={50}
                            style={{ marginBottom: 10, marginTop: 40 }}
                        />
                        <Skeleton variant="rectangular" width={250} height={40} style={{ marginBottom: 30 }} />
                        <Skeleton variant="rectangular" width="100%" height={400} style={{ marginBottom: 40 }} />
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={300}
                                    style={{ marginBottom: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={300}
                                    style={{ marginBottom: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={300}
                                    style={{ marginBottom: 10 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
}

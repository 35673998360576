import App from './App';
import { StepperStoreProvider } from './utils/stores/bookingSections/StepperStore';
import { BookingStoreProvider } from './utils/stores/BookingStore';
import { ProductsStoreProvider } from './utils/stores/ProductsStore';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <ProductsStoreProvider>
        <StepperStoreProvider>
            <BookingStoreProvider>
                <App />
            </BookingStoreProvider>
        </StepperStoreProvider>
    </ProductsStoreProvider>
);

import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const commonStyles = makeStyles()((theme) => ({
    container: {
        padding: 25,
        [theme.breakpoints.only('sm')]: {
            padding: '30px 0 0 0'
        },
        [theme.breakpoints.only('xs')]: {
            padding: 10
        }
    },
    title: {
        fontSize: 38,
        color: 'rgba(20, 20, 42, 1)',
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 500,
        paddingBottom: 15,
        [theme.breakpoints.only('xs')]: {
            paddingTop: 25,
            fontSize: 30
        }
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 300,
        paddingBottom: 40,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 25,
            fontSize: 18
        }
    },
    fieldInputSection: {
        padding: 15,
        [theme.breakpoints.only('xs')]: {
            padding: '10px 0'
        }
    },
    fieldInputItem: {
        width: '90%',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        },
        maxWidth: 500
    },
    overflow: {
        maxHeight: '50vh',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            maxHeight: 'unset'
        }
    },
    displayError: {
        fontSize: 80,
        fontWeight: 300,
        paddingBottom: 40,
        color: 'red'
    },
    bookingButton: {
        height: 52,
        width: '100%',
        maxWidth: 450,
        backgroundColor: 'rgb(0 168 0)',
        color: 'white'
    },
    errorButton: {
        height: 52,
        width: '100%',
        maxWidth: 450,
        color: 'red',
        borderColor: 'red'
    },
    encryptSect: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    cardContainer: {
        maxWidth: 550,
        [theme.breakpoints.down('lg')]: {
            margin: 'auto',
            maxWidth: 'unset',
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'center'
        }
    },
    extraTitle: {
        fontSize: 22,
        color: 'rgba(20, 20, 42, 1)',
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        opacity: 0.85,
        paddingBottom: 7,
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        }
    },
    quantityTitle: {
        fontSize: 22,
        color: 'rgba(20, 20, 42, 1)',
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        opacity: 0.85,
        paddingBottom: 7,
        [theme.breakpoints.only('xs')]: {
            fontSize: 19,
            paddingBottom: 0
        }
    },
    extraDescription: {
        fontSize: 16,
        fontWeight: 300,
        [theme.breakpoints.only('xs')]: {
            lineHeight: '137%'
        }
    },
    extraItemQuantity: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            marginTop: 15
        }
    },
    extraItemImage: {
        paddingRight: 25
    },
    extraItemText: {
        paddingRight: 40,
        [theme.breakpoints.only('xs')]: {
            paddingRight: 0
        }
    },
    extraQuantityInner: {
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    locationTitle: {
        fontSize: 19,
        letterSpacing: '-.007rem',
        fontWeight: 400,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17
        }
    },
    location: {
        fontSize: 18,
        letterSpacing: '-.007rem',
        fontWeight: 300,
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        }
    },
    pickUpCheckBox: {
        padding: '10px 15px',
        borderRadius: 10,
        '& .MuiTypography-body1': {
            fontSize: 24,
            fontWeight: 500,
            [theme.breakpoints.down('md')]: {
                fontSize: 21
            }
        },
        marginLeft: 15,
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0
        },
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.primary.light,
        color: theme.palette.primary.main
    },
    checkLabel: {
        fontSize: 18,
        padding: '10px 15px',
        fontWeight: 300,
        [theme.breakpoints.down('md')]: {
            fontSize: 16.5,
            paddingRight: 0
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0
        }
    },
    disableView: {
        backgroundColor: 'black',
        opacity: 0.5
    },
    dateSelect: {
        paddingRight: 15,
        maxWidth: 300,
        [theme.breakpoints.only('sm')]: {
            padding: 0,
            marginTop: 15,
            marginBottom: 20
        },
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            marginBottom: 20,
            marginTop: 15,
            maxWidth: 'unset'
        }
    },
    timeSelect: {
        paddingLeft: 15,
        maxWidth: 300,
        [theme.breakpoints.only('sm')]: {
            padding: 0,
            marginBottom: 15
        },
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            maxWidth: 'unset',
            marginBottom: 15
        }
    }
}));

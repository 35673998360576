import { CircularProgress, Grid, TextField } from '@mui/material';
import { sum } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore, SessionValue } from '../../../utils/stores/ProductsStore';
import { makeStyles } from 'tss-react/mui';
import { gradients } from '../../../utils/theme';

const availabilityStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        [theme.breakpoints.only('xs')]: {
            padding: '0 20px'
        }
    },
    paper: {
        zIndex: 8,
        padding: '15px 25px 20px',
        width: '80%',
        '@media (max-width:999px)': {
            width: '100%'
        },
        borderRadius: 10,
        [theme.breakpoints.only('sm')]: {
            padding: '5px 10px 30px'
        },
        [theme.breakpoints.only('xs')]: {
            zIndex: 5,
            padding: 0
        }
    },
    menu: {
        width: 190
    },
    menuButton: {
        width: '90%',
        height: 54,
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 14.5,
        paddingRight: 14.5,
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    },
    menuItem: {
        width: 280,
        paddingRight: 2
    },
    selectFields: {
        width: '90%',
        '& .MuiOutlinedInput-root': {
            height: 54
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    loadContainer: {
        width: '90%',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        height: 54,
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderStyle: 'solid',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgb(0 0 0 / 23%)'
    },
    title: {
        marginBottom: 15,
        width: '100%',
        textAlign: 'left',
        fontSize: 22,
        backgroundImage: gradients.main,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        [theme.breakpoints.down('md')]: {
            paddingTop: 15
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '5%'
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: '5%'
        }
    },
    buttonContainer: {
        justifyContent: 'center',
        alignContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'center',
            marginTop: 25
        }
    },
    button: {
        width: '80%',
        height: 54,
        backgroundImage: gradients.circular,
        [theme.breakpoints.down('md')]: {
            width: '90%'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '30px 0'
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    innerContainer: {
        [theme.breakpoints.only('xs')]: {
            alignItems: 'center',
            flexDirection: 'column'
        }
    },
    sectionItem: {
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignText: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            width: '90%'
        }
    },
    packageNote: {
        width: '100%',
        display: 'flex',
        marginTop: 20,
        [theme.breakpoints.only('sm')]: {
            padding: '0 2.5%'
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: 15,
            justifyContent: 'center',
            padding: 0
        }
    },
    packageNoteText: {
        color: theme.palette.text.soft,
        fontWeight: 400,
        fontSize: 17,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.3,
        paddingRight: '5%',
        [theme.breakpoints.only('xs')]: {
            paddingRight: 0
        }
    },
    calendarSize: {
        '& .MuiPopover-paper': {
            [theme.breakpoints.only('xs')]: {
                maxWidth: 'calc(100% - 10px)',
                overflowX: 'scroll',
                color: 'pink'
            }
        }
    },
    innerGridItem: {
        width: '100%',
        [theme.breakpoints.only('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));

interface SessionSelectProps {
    readonly isPackage?: boolean;
}

export default function SessionSelect({ isPackage }: SessionSelectProps) {
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const classes = availabilityStyles().classes;
    const isMounted = IsMountedWrapper();
    const [localTourDisplay, setLocalTourDisplay] = useState<string>(
        isPackage && (JSON.stringify({ time: bookingController.packageBooking.data.tour.startTimeLocal }) || '')
    );
    const showLoading = productController.isLoading;
    const showPackageLoading =
        productController.availability.packageSessions &&
        !productController.availability.packageSessions.find((s) =>
            moment(s.startTime).isSame(productController.availability?.packageDepartureDate, 'day')
        );
    const departureDate = isPackage
        ? productController.availability.packageDepartureDate
        : productController.availability.departureDate;
    const sessionArray = isPackage
        ? productController.availability.packageSessions
        : productController.availability.sessions;

    const handletimechange = (time: any) => {
        isPackage
            ? bookingController.packageBooking.setData(
                  (prev) => ({
                      ...prev,
                      tour: {
                          ...prev.tour,
                          startTimeLocal: moment(time).utcOffset(10).toISOString(true)
                      }
                  }),
                  isMounted
              )
            : bookingController.setBooking(
                  (prev) => ({
                      ...prev,
                      tour: {
                          ...prev.tour,
                          startTimeLocal: moment(time).utcOffset(10).toISOString(true)
                      }
                  }),
                  isMounted
              );
    };

    const changeTourTime = (event) => {
        if (event.target.value) {
            const res = JSON.parse(event.target.value);
            const { time } = res;
            handletimechange(time);
            setLocalTourDisplay(event.target.value);
        }
    };

    const removeUnavailable = (sessions: SessionValue[]) => {
        const cutOffTime = moment().add(
            Number(
                isPackage
                    ? productController.packageProduct.data.minimumNoticeMinutes
                    : productController.product.minimumNoticeMinutes
            ),
            'm'
        );
        const availSessions =
            sessions && isPackage
                ? sessions
                      .filter(
                          (session) =>
                              moment(session.startTime).isAfter(cutOffTime) &&
                              session.seatsRemaining >=
                                  sum(
                                      bookingController.packageBooking.data.quantities.map((q) => q.seatsUsed * q.value)
                                  )
                      )
                      .map((session) => ({ ...session, startTime: session.startTime }))
                : sessions
                      .filter((session) => moment(session.startTime).isAfter(cutOffTime))
                      .map((session) => ({ ...session, startTime: session.startTime }));
        return availSessions;
    };

    const displayByDay = (date: string, sessions: SessionValue[]) => {
        const localSessions =
            sessions &&
            sessions.map((session) => ({
                ...session,
                startTime: moment(session.startTime).utcOffset(10).toISOString(true)
            }));
        const dailySessions =
            localSessions &&
            localSessions
                .filter((session) => moment(session.startTime).isSame(moment(date), 'day'))
                .map((session) => ({ ...session, startTime: session.startTime }));
        return dailySessions;
    };

    return (
        <>
            {(isPackage ? showPackageLoading : showLoading) ? (
                <Grid item xs={12} className={classes.sectionItem}>
                    <div className={classes.loadContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            disableShrink
                            size={24}
                            thickness={4}
                        />
                    </div>
                </Grid>
            ) : (
                <Grid item xs={12} className={classes.sectionItem}>
                    <TextField
                        select
                        className={classes.selectFields}
                        style={{ width: isPackage && '100%' }}
                        value={localTourDisplay}
                        error={
                            isPackage && bookingController.customerErrors.packageDate === 'Check your departure time'
                        }
                        helperText={
                            isPackage &&
                            bookingController.customerErrors.packageDate === 'Check your departure time' &&
                            bookingController.customerErrors.packageDate
                        }
                        onChange={changeTourTime}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu
                            }
                        }}
                        variant="outlined"
                        disabled={showLoading}
                    >
                        <option value="">
                            {displayByDay(departureDate, sessionArray) &&
                            removeUnavailable(displayByDay(departureDate, sessionArray)).length === 0
                                ? 'No Availability'
                                : 'Select Time'}
                        </option>
                        {displayByDay(departureDate, sessionArray) &&
                            removeUnavailable(displayByDay(departureDate, sessionArray)).map((session) => {
                                const { seatsRemaining, startTime } = session;
                                if (session.seatsRemaining > 0) {
                                    return (
                                        <option value={JSON.stringify({ time: startTime })} key={session.id}>
                                            {`${moment(startTime)
                                                .utcOffset(10)
                                                .format('h:mm a')} - ${seatsRemaining} remaining`}
                                        </option>
                                    );
                                } else {
                                    return (
                                        <option value={JSON.stringify({ time: startTime })} key={session.id}>
                                            {startTime !== 'Invalid date'
                                                ? `${moment(startTime).utcOffset(10).format('h:mm a')} - Sold Out`
                                                : 'No Departures Available'}
                                        </option>
                                    );
                                }
                            })}
                    </TextField>
                </Grid>
            )}
        </>
    );
}

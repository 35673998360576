export const widgetStyles = {
    paper: {
        p: { xs: 0, sm: 2 },
        pt: { xs: 0 },
        border: { xs: 'none', sm: '1px solid rgba(0, 0, 0, 0.12)' },
        borderRadius: 2,
        height: 1
    },
    paperLeft: {
        pb: 2,
        ml: { xs: 0, sm: 2 },
        mr: { xs: 1, md: 2 }
    },
    paperRight: {
        pb: 2,
        ml: { xs: 1, md: 2 },
        mr: { xs: 0, sm: 2 }
    },
    gridContainer: {
        pb: 2
    },
    gridItem: {
        width: 1
    },
    detailText: {
        fontWeight: 400,
        color: 'text.soft',
        fontSize: { xs: '1rem', lg: '1.1rem' }
    }
};

import React, { useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { makeStyles } from 'tss-react/mui';
import { BookingStore } from '../../utils/stores/BookingStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SummaryContent } from './BookingFormSummary';

const summaryStyles = makeStyles()((theme) => ({
    container: {
        padding: '32px 32px 5px',
        [theme.breakpoints.only('xs')]: {
            padding: '10px 10px 5px'
        }
    },
    paper: {
        padding: '20px 20px 8px',
        [theme.breakpoints.only('xs')]: {
            padding: '0 5px 8px'
        },
        borderRadius: 10,
        '& .MuiAccordionSummary-root': {
            [theme.breakpoints.only('xs')]: {
                padding: '5px 10px'
            }
        },
        '& .MuiAccordionDetails-root': {
            [theme.breakpoints.only('xs')]: {
                padding: '0 10px'
            }
        },
        '& .MuiAccordionSummary-content': {
            [theme.breakpoints.only('xs')]: {
                marginBottom: 4
            }
        }
    },
    divider: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D9DBE9',
        width: '100%',
        height: 0
    },
    itemText: {
        fontSize: 18,
        [theme.breakpoints.only('xs')]: {
            fontSize: 15,
            marginBottom: 15
        },
        lineHeight: '110%',
        color: '#646673',
        marginBottom: 20,
        fontWeight: 300
    },
    subtitle: {
        marginBottom: 12,
        fontSize: 16,
        [theme.breakpoints.only('xs')]: {
            fontSize: 15,
            marginBottom: 0
        }
    }
}));

export default function BookingFormSummaryAccordion() {
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const { classes, cx } = summaryStyles();

    return (
        <Box className={classes.container}>
            <Accordion className={classes.paper} style={{ maxHeight: 760, overflowY: 'auto' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="booking summary accordion"
                    id="summary panel"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 400, fontSize: 24, lineHeight: '115%', paddingBottom: 10 }}
                            >
                                {productController.product.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={cx(classes.itemText, classes.subtitle)}>
                                {productController.product.tagline}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <SummaryContent
                        booking={bookingController.booking}
                        packageBooking={bookingController.packageBooking.data || undefined}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Tick from '@mui/icons-material/CheckCircleOutline';
import Cross from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty, unionBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { commonStyles } from '../bookings/formSections/commonStyles';
import { ParticipantInfoFieldView, ParticipantViewProps } from '../bookings/formSections/ParticipantInfoFields';

export function ManageParticipantView(props: ParticipantViewProps) {
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const classes = commonStyles().classes;
    const minMediumWidth = useMediaQuery('(min-width: 800px');
    const infoFields = productController.product?.allowPackage
        ? unionBy(
              productController.product?.participantInfoFields,
              productController.packageProduct.data?.participantInfoFields,
              'label'
          ).filter((field) => field.label !== 'Number of Passengers')
        : productController.product?.participantInfoFields;

    const getByLabel = (label: string) =>
        (bookingController.booking.participants || [])[props.participantIndex]?.fields?.find(
            (field) => field.label === label
        ) || {};

    const checkError = () =>
        (
            [bookingController.validateParticipant(bookingController.booking.participants[props.participantIndex])] ||
            []
        ).filter((error) => !isEmpty(error)).length === 0;

    const [participantValid, setParticipantValid] = useState(checkError());

    useEffect(() => {
        setParticipantValid(checkError());
    }, [bookingController.booking.participants, productController.product]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`partipant-content-${props.participantIndex + 1}`}
                id={`partipant-content-header ${props.participantIndex + 1}`}
            >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {participantValid ? (
                        <Tick style={{ marginRight: 5, padding: 1, color: '#24bb00' }} />
                    ) : (
                        <Cross color="error" style={{ marginRight: 5, padding: 1 }} />
                    )}
                    <Typography>Participant/Option {props.participantIndex + 1}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1} alignItems="flex-end" className={classes.fieldInputSection}>
                    {((infoFields as any) || []).map((participantInfoField, index) => (
                        <Grid
                            item
                            style={{ paddingBottom: 8 }}
                            key={index}
                            xs={(infoFields?.length || 0) > 1 ? (minMediumWidth ? 6 : 12) : 12}
                        >
                            <ParticipantInfoFieldView
                                className={classes.fieldInputItem}
                                field={participantInfoField}
                                participantIndex={props.participantIndex}
                                onChange={(value) =>
                                    bookingController.handleParticipantInfoFieldChange(props.participantIndex, {
                                        ...participantInfoField,
                                        value
                                    })
                                }
                                value={getByLabel(participantInfoField.label || '').value}
                            />
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Button, Container, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { isOnSale, productURL } from '../../utils/functions/common';
import { Product } from '../../utils/models/products';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { gradients } from '../../utils/theme';

interface HeroSectionProps {
    readonly productCodes: string[];
}

const heroStyles = makeStyles()((theme) => ({
    container: {
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            paddingTop: 50
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 10
        }
    },
    textSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingRight: 40,
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
            alignItems: 'center'
        }
    },
    textSectionInner: {
        maxWidth: 440,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            alignItems: 'center'
        }
    },
    sectionPadding: {
        paddingTop: 100,
        paddingBottom: 100,
        [theme.breakpoints.only('sm')]: {
            paddingTop: 30,
            paddingBottom: 20
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 10,
            paddingBottom: 20
        }
    },
    imageSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 20,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            alignItems: 'center'
        }
    },
    imageSectionInner: {
        maxWidth: 380,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            maxWidth: 750
        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'center',
            maxWidth: 380
        }
    },
    subtitle: {
        fontSize: 22,
        opacity: 0.8,
        maxWidth: '85%',
        fontWeight: 300,
        paddingBottom: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: 18
        }
    },
    price: {
        fontSize: 24,
        opacity: 0.9,
        fontWeight: 400,
        paddingBottom: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    },
    imageDiv: {
        overflow: 'hidden',
        borderRadius: 10,
        margin: 10,
        [theme.breakpoints.down('md')]: {
            margin: 6
        }
    },
    image: {
        objectFit: 'cover',
        borderRadius: 10,
        cursor: 'pointer'
    },
    nonSelectedImage: {
        opacity: 0.5
    },
    selectedImage: {
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;'
    },
    zoomImage: {
        transform: 'scale(1.2)'
    },
    button: {
        width: 200,
        height: 46,
        backgroundImage: gradients.circular,
        color: 'white',
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
            marginTop: 20
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%'
        }
    },
    onSale: {
        width: 125,
        height: 38,
        background: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 500
    }
}));

export default function HeroSection({ productCodes }: HeroSectionProps) {
    const classes = heroStyles().classes;
    const productController = useContext(ProductsStore);
    const theme = useTheme();
    const imageHeight = useMediaQuery(theme.breakpoints.down('md')) ? 125 : 170;
    const imageWidth = imageHeight;
    const [imageLoading, setImageLoading] = useState(
        productCodes.map((code) => ({ productCode: code, isLoading: true }))
    );

    const changeSelectedProduct = (code: string) => {
        const product = productController.products.filter((prod) => prod.productCode === code)[0];
        return product;
    };

    const [selectedProduct, setSelectedProduct] = useState<Product>(changeSelectedProduct(productCodes[0]));

    useEffect(() => {
        setSelectedProduct(changeSelectedProduct(productCodes[0]));
    }, [productController.products]);

    return (
        <Container maxWidth="lg">
            <Grid container justifyContent="center" className={classes.container}>
                <Grid item xs={12} md={6} className={clsx(classes.textSection, classes.sectionPadding)}>
                    <Grid item xs={12} className={classes.textSectionInner}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: { xs: 30, md: 40 },
                                color: 'rgba(20, 20, 42, 1)',
                                lineHeight: '115%',
                                letterSpacing: '-1px',
                                fontWeight: 500,
                                pb: 1.8,
                                textDecoration: 'none'
                            }}
                            component={Link}
                            to={selectedProduct ? productURL(selectedProduct?.name) : '/'}
                        >
                            {selectedProduct ? selectedProduct.name : <Skeleton width={280} />}
                        </Typography>
                        <Typography variant="h5" className={classes.subtitle}>
                            {selectedProduct ? selectedProduct.tagline : <Skeleton width={160} />}
                        </Typography>
                        {selectedProduct ? (
                            isOnSale(selectedProduct) ? (
                                <span style={{ display: 'flex' }}>
                                    <Typography
                                        className={classes.onSale}
                                        component={Link}
                                        to={selectedProduct ? productURL(selectedProduct?.name) : '/'}
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        <LoyaltyIcon style={{ marginRight: 5, padding: 1 }} />
                                        On Sale!
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        className={classes.price}
                                        style={{ textDecoration: 'line-through', marginLeft: 15 }}
                                    >
                                        $ {selectedProduct.displayPrice.price}
                                    </Typography>
                                    <Typography variant="h6" className={classes.price} style={{ marginLeft: 15 }}>
                                        $ {isOnSale(selectedProduct)}
                                    </Typography>
                                </span>
                            ) : (
                                <Typography variant="h6" className={classes.price}>
                                    $ {selectedProduct.displayPrice.price}
                                </Typography>
                            )
                        ) : (
                            <Skeleton width={130} />
                        )}
                        {productController.isLoading || !selectedProduct ? (
                            <Skeleton width="40%" height={46} />
                        ) : (
                            <Button
                                variant="contained"
                                className={classes.button}
                                component={Link}
                                to={productURL(selectedProduct.name)}
                            >
                                Check It Out
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={clsx(classes.imageSection, classes.sectionPadding)}>
                    <Grid container className={classes.imageSectionInner}>
                        {productCodes.map((code, i) => {
                            const product = productController.products.filter((prod) => prod.productCode === code)[0];
                            const isSelected = selectedProduct?.productCode === code;
                            return (
                                <div key={`featureTile${i}`}>
                                    <div
                                        className={clsx(classes.imageDiv, { [classes.selectedImage]: isSelected })}
                                        style={{
                                            height: imageHeight,
                                            width: imageWidth
                                        }}
                                    >
                                        {(productController.isLoading || !product || imageLoading[i].isLoading) && (
                                            <Skeleton variant="rectangular" height="100%" width="100%" />
                                        )}
                                        <div
                                            className={clsx({
                                                [classes.nonSelectedImage]: !isSelected
                                            })}
                                            style={{ height: '100%', width: '100%' }}
                                        >
                                            <img
                                                key={product?.productCode}
                                                src={product?.photos[0].mediumURL}
                                                alt={product?.name}
                                                style={{ height: '100%', width: '100%' }}
                                                className={clsx(classes.image, {
                                                    [classes.zoomImage]: isSelected
                                                })}
                                                onClick={() =>
                                                    setSelectedProduct(changeSelectedProduct(product.productCode))
                                                }
                                                onLoad={() =>
                                                    setImageLoading((prev) =>
                                                        prev.map((img) =>
                                                            img.productCode === product.productCode
                                                                ? { ...img, isLoading: false }
                                                                : img
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

import { isEmpty, unionBy } from 'lodash';
import { useContext } from 'react';
import { State, StateSetterType } from '../../functions/hooks';
import IsMountedWrapper from '../../functions/isMountedWrapper';
import { Booking, Participant, ParticipantInfoField, ParticipantInfoFieldType } from '../../models/bookings';
import { WrappedSetter } from '../BaseStore';
import { ProductsStore } from '../ProductsStore';

export interface ParticipantInfoStepControllerValue {
    readonly participantErrors: { [label: string]: string }[];
    readonly handleParticipantInfoFieldChange: (participantIndex: number, infoField: ParticipantInfoField) => void;
    readonly isParticipantInfoStepValid: () => boolean;
    readonly setParticipantErrors: StateSetterType<{ [label: string]: string }[]>;
    readonly validateParticipant: (participant: Participant) => { [label: string]: string };
}

export const setOrDelete = (object: any, key: any, value?: any) => {
    const tmp = { ...object };
    if (value) {
        tmp[key] = value;
    } else {
        delete tmp[key];
    }
    return tmp;
};

export default function ParticipantInfoStepController(
    setBooking: WrappedSetter<Booking>,
    booking: Booking
): ParticipantInfoStepControllerValue {
    const productController = useContext(ProductsStore);
    const isMounted = IsMountedWrapper();
    const participantErrors = State<{ [label: string]: string }[]>([], isMounted);
    const infoFields = productController.product?.allowPackage
        ? unionBy(
              productController.product?.participantInfoFields,
              productController.packageProduct.data?.participantInfoFields,
              'label'
          ).filter((field) => field.label !== 'Number of Passengers')
        : productController.product?.participantInfoFields;

    const updateOrSetField = (fields: ParticipantInfoField[], field: ParticipantInfoField) => {
        const currentField = (fields || []).find((f) => f.label === field.label);
        if (currentField) {
            return fields.map((f) => (f.label === field.label ? field : f));
        }
        return fields.concat(field);
    };

    const handleParticipantInfoFieldChange = (participantIndex: number, infoField: ParticipantInfoField) => {
        setBooking((prev) => {
            const participant = (prev.participants || [])[participantIndex];
            const fields = updateOrSetField(participant?.fields || [], infoField);
            const participants = (prev.participants || []).map((p, index) =>
                index === participantIndex ? { ...participant, fields } : p
            );
            return { ...prev, participants };
        }, isMounted);
        participantErrors.set((prev) =>
            prev.map((error, index) =>
                index !== participantIndex ? error : setOrDelete(error, infoField.label, undefined)
            )
        );
    };

    const validateParticipant = (participant: Participant) =>
        (infoFields || []).reduce((errors, productParticipantField) => {
            const participantField =
                (participant?.fields || []).find((f) => f.label === productParticipantField.label) ||
                productParticipantField;
            const error = getValidatetorFunction(participantField)(participantField);
            return error ? { ...errors, [participantField.label!]: error } : errors;
        }, {} as { [label: string]: string });

    const isParticipantInfoStepValid = () => {
        const errors = (booking.participants || []).map(validateParticipant) as {
            [label: string]: string;
        }[];
        participantErrors.set(errors);
        return errors.filter((error) => !isEmpty(error)).length === 0;
    };

    return {
        participantErrors: participantErrors.data,
        setParticipantErrors: participantErrors.set,
        handleParticipantInfoFieldChange,
        isParticipantInfoStepValid,
        validateParticipant
    };
}

export const getValidatetorFunction = (field: ParticipantInfoField) => {
    switch (field.fieldType) {
        case ParticipantInfoFieldType.TEXT:
            return isTextFieldValid;
        case ParticipantInfoFieldType.DATE:
            return isDateValid;
        case ParticipantInfoFieldType.BOOLEAN:
            return isValidBoolean;
        case ParticipantInfoFieldType.NUMBER:
            return isValidNumber;
        case ParticipantInfoFieldType.LIST:
            return isValidListOption;
        default:
            return () => {};
    }
};

const isTextFieldValid = (field: ParticipantInfoField) => (isEmpty(field.value) ? 'This field is required' : undefined);

const isDateValid = (field: ParticipantInfoField) => {
    if (isEmpty(field.value)) {
        return 'This field is required';
    }
    if (field.value === 'Invalid date') {
        return 'Invalid Date';
    }
};

const isValidBoolean = (field: ParticipantInfoField) =>
    field.value !== 'true' && field.value !== 'false' ? 'Invalid field value' : undefined;

const isValidNumber = (field: ParticipantInfoField) =>
    Number.isNaN(Number(field.value)) ? 'Invalid number' : undefined;

const isValidListOption = (field: ParticipantInfoField) => {
    if (isEmpty(field.value)) {
        return 'This field is required';
    }
    if (!(field.listOptions || []).includes(field.value || '')) {
        return 'Invalid selected option';
    }
};

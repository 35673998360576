import axios from 'axios';
import { map, sum } from 'lodash';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import t from 'typy';
import { WrappedUseState, WrappedUseStateReturn } from '../functions/hooks';
import IsMountedWrapper from '../functions/isMountedWrapper';
import { convertToRezdyBooking, createRezdyPackageBooking } from '../functions/rezdy';
import { checkConversions } from '../functions/sales';
import { Booking, EMPTY_BOOKING, EMPTY_PACKAGE_BOOKING, GSLEntities } from '../models/bookings';
import { PriceOption } from '../models/products';
import { BaseStore, BaseStoreValues, WrappedSetter } from './BaseStore';
import { ProductsStore } from './ProductsStore';
import CustomerInfoStepController, { CustomerInfoStepControllerValue } from './bookingSections/CustomerInfoController';
import ExtraSelectionStepController, {
    ExtraSelectionStepControllerValue
} from './bookingSections/ExtraSelectionStepController';
import ManageBookingController, { ManageBookingControllerValue } from './bookingSections/ManageBookingController';
import ParticipantInfoStepController, {
    ParticipantInfoStepControllerValue
} from './bookingSections/ParticipantInfoController';
import PickUpStepController, { PickUpStepControllerValue } from './bookingSections/PickUpStepController';
import { StepperStore, StepperStoreValue } from './bookingSections/StepperStore';
import { DefaultProviderProps, createStore, createStoreContext } from './common';

const apiURL =
    process.env.NODE_ENV === 'production' ? 'https://gslwhitsundays.com.au/v2/' : 'http://localhost:5000/v2/';

class CardDetailKey {
    constructor(
        readonly Name?: string,
        readonly Number?: string,
        readonly ExpiryMonth?: string,
        readonly ExpiryYear?: string,
        readonly CVN?: string
    ) {
        this.Name = '';
    }
}

export interface CardDetails extends CardDetailKey {}

export interface PaymentObject {
    readonly cardDetails?: CardDetails;
    readonly paymentError?: boolean;
    readonly errorDetails?: any;
    readonly amount?: number;
}

interface CompletedBookings {
    readonly booking: Booking;
    readonly package?: Booking;
}

export interface BookingStoreValue
    extends BaseStoreValues<Booking>,
        StepperStoreValue,
        CustomerInfoStepControllerValue,
        ExtraSelectionStepControllerValue,
        ParticipantInfoStepControllerValue,
        ManageBookingControllerValue,
        PickUpStepControllerValue {
    readonly booking: Booking;
    readonly setBooking: WrappedSetter<Booking>;
    readonly packageBooking: WrappedUseStateReturn<Booking>;
    readonly clearController: () => void;
    readonly payment: WrappedUseStateReturn<PaymentObject>;
    readonly completedBookings: WrappedUseStateReturn<CompletedBookings>;
    readonly updateCardDetails: (event: any, isEncrypted: boolean) => void;
    readonly processBooking: () => void;
    readonly handleQuantityChange: (event: any) => void;
    readonly calcBookingTotal: () => void;
    readonly checkFormErrors: (bookingStep: number) => boolean;
}

export const BookingStore = createStoreContext<BookingStoreValue>();

export const BookingStoreProvider = ({ children }: DefaultProviderProps) => {
    const base = BaseStore<Booking>(EMPTY_BOOKING);
    const isMounted = IsMountedWrapper();
    const productController = useContext(ProductsStore);
    const booking: Booking = base.mainObject;
    const packageBooking = WrappedUseState<Booking>(EMPTY_PACKAGE_BOOKING);
    const setBooking = base.setMainObject;
    const payment = WrappedUseState<PaymentObject>({});
    const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
    const existingBooking: Booking = undefined;
    const completedBookings = WrappedUseState<CompletedBookings>({ booking: existingBooking || EMPTY_BOOKING });
    const stepper = useContext(StepperStore);
    const participantInfoStepController = ParticipantInfoStepController(setBooking, booking);
    const customerInfoStepController = CustomerInfoStepController(
        setBooking,
        booking,
        packageBooking.data,
        productController.availability
    );
    const extrasStepController = ExtraSelectionStepController(setBooking, booking, productController.product);
    const pickUpStepController = PickUpStepController(
        booking,
        setBooking,
        packageBooking.data,
        packageBooking.setData,
        productController.product,
        productController.packageProduct.data
    );
    const manageBookingController = ManageBookingController(
        setBooking,
        booking,
        base,
        apiURL,
        productController.products
    );

    const clearController = () => {
        setBooking(EMPTY_BOOKING, isMounted);
        packageBooking.setData(EMPTY_PACKAGE_BOOKING, isMounted);
        participantInfoStepController.setParticipantErrors([]);
        customerInfoStepController.setCustomerErrors([]);
        payment.setData({}, isMounted);
        manageBookingController.rezdyResults.setData(undefined, isMounted);
        manageBookingController.originalBooking.setData(undefined, isMounted);
    };

    const updateCardDetails = (event: any, isEncrypted: boolean) => {
        const key = event.target.name as string;
        const value = isEncrypted
            ? (window as any).eCrypt.encryptValue(event.target.value, encryptKey)
            : (event.target.value as string);
        payment.setData(
            (prev) => ({
                ...prev,
                cardDetails: {
                    ...prev.cardDetails,
                    [key]: value
                }
            }),
            isMounted
        );
    };

    const farePricing = (prices: PriceOption[]) => {
        const tempBooking = { ...booking };
        const newBooking: Booking = {
            ...tempBooking,
            quantities: (tempBooking?.quantities || []).map((q) => ({
                label: q?.label || '',
                price: (prices || []).find((po) => po?.label === q?.label)?.price || 0,
                value: q?.value || 0,
                seatsUsed: q?.seatsUsed || 1
            }))
        };
        setBooking(newBooking, isMounted);
    };

    const bookingPrices = () => {
        const salesExist = (productController.product?.sales || []).length > 0;
        const currentSale = salesExist
            ? productController.product.sales.find((sale) =>
                  moment(booking?.tour?.startTimeLocal).isBetween(sale.startDate, sale.endDate)
              )
            : undefined;
        if (currentSale !== undefined) {
            farePricing(currentSale.fareTypes);
        } else {
            const departureTime = moment(booking?.tour?.startTimeLocal).toISOString();
            const isValid =
                (productController?.availability?.sessions || []).find((sesh) =>
                    moment(sesh.startTime).isSame(moment(departureTime))
                ) !== undefined;
            const priceOptions = isValid
                ? (productController.availability.sessions
                      .find((sesh) => moment(sesh.startTime).isSame(moment(departureTime)))
                      ?.priceOptions.map((po) => ({
                          label: po.label,
                          price: po.price,
                          seatsUsed: po.seatsUsed
                      })) as PriceOption[])
                : productController.product?.priceOptions;
            farePricing(priceOptions);
        }
    };

    const calcBookingTotal = () => {
        const fareTotal = sum(map(booking.quantities, (x) => x.price * x.value));
        const extrasTotal = booking.extras ? sum(map(booking.extras, (x) => (x.price * x.quantity) / 100)) : 0;
        setBooking(
            (prev) => ({
                ...prev,
                totalAmount: fareTotal + extrasTotal
            }),
            isMounted
        );
        payment.setData(
            (prev) => ({
                ...prev,
                amount: (fareTotal + extrasTotal) * 100
            }),
            isMounted
        );
    };

    useEffect(() => {
        calcBookingTotal();
    }, [booking.quantities, booking.extras, booking?.tour?.startTimeLocal]);

    useEffect(() => {
        bookingPrices();
    }, [booking?.tour?.startTimeLocal]);

    useEffect(() => {
        productController.product?.allowPackage &&
            packageBooking.setData(
                (prev) => ({
                    ...prev,
                    quantities: [
                        {
                            label: productController?.product.packageFare.label,
                            value: sum(booking.quantities.map((q) => q.value)),
                            seatsUsed: productController?.product.packageFare?.seatsUsed
                        }
                    ]
                }),
                isMounted
            );
    }, [booking.quantities]);

    useEffect(() => {
        productController.product?.allowPackage &&
            packageBooking.setData(
                (prev) => ({
                    ...prev,
                    customer: booking.customer
                }),
                isMounted
            );
    }, [booking.customer]);

    const handleQuantityChange = (event: any) => {
        const label = event.target.name;
        const quantity = Number(event.target.value);
        const index = productController.product.priceOptions.findIndex((x) => x.label.includes(label));
        const price = t(productController.product.priceOptions[index].price).safeNumber;
        const fareOption: PriceOption = {
            label: label,
            price: price,
            value: quantity
        };
        const tempQuantities = booking.quantities ? booking.quantities : [];
        const replaceIndex = booking.quantities ? booking.quantities.findIndex((x) => x.label.includes(label)) : 0;
        replaceIndex === -1 ? tempQuantities.concat([fareOption]) : tempQuantities.splice(replaceIndex, 1, fareOption);
        setBooking(
            (prev) => ({
                ...prev,
                quantities: tempQuantities
            }),
            isMounted
        );
    };

    const submitBooking = async (bookingObject: Booking, isPackage?: boolean) => {
        try {
            const res = await axios.post(
                `${apiURL}rezdy/bookings?entity=${bookingObject.entity}`,
                convertToRezdyBooking(bookingObject, productController.product)
            );
            if (res.data.success) {
                try {
                    const packageRes =
                        isPackage &&
                        (await axios.post(
                            `${apiURL}rezdy/bookings?entity=${productController.packageProduct.data.entity}`,
                            createRezdyPackageBooking(
                                {
                                    ...bookingObject,
                                    bookingResponse: res.data
                                },
                                packageBooking.data,
                                productController.product,
                                productController.packageProduct.data
                            )
                        ));
                    if (packageRes?.data?.success) {
                        packageBooking.setData(
                            (prev) => ({
                                ...prev,
                                bookingResponse: packageRes.data,
                                bookingComplete: true
                            }),
                            isMounted
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
                setBooking((prev) => ({ ...prev, bookingResponse: res.data, bookingComplete: true }), isMounted);
                axios.post(`${apiURL}email/booking?entity=${GSLEntities.GSL_WHITSUNDAYS}`, {
                    ...bookingObject,
                    packageBooking: packageBooking.data,
                    bookingResponse: res.data
                });
                checkConversions({ ...bookingObject, bookingResponse: res.data });
            } else {
                setBooking((prev) => ({ ...prev, bookingResponse: res.data }), isMounted);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const processBooking = async () => {
        base.setIsLoading(true, isMounted);
        try {
            const response = await axios.post(`${apiURL}payments/process`, {
                ...payment.data,
                cardDetails: {
                    ...payment.data.cardDetails,
                    ExpiryYear: payment.data.cardDetails.ExpiryYear.slice(2)
                },
                amount: booking.totalAmount * 100
            });
            if (response.data.success === true) {
                payment.setData(
                    (prev) => ({
                        ...prev,
                        paymentError: false
                    }),
                    isMounted
                );
                setBooking(
                    (prev) => ({
                        ...prev,
                        payment: {
                            amount: response.data.amount / 100,
                            paymentId: response.data.transID,
                            date: moment().toISOString(),
                            type: 'CREDITCARD'
                        }
                    }),
                    isMounted
                );
                await submitBooking(
                    {
                        ...booking,
                        payment: {
                            amount: response.data.amount / 100,
                            paymentId: response.data.transID,
                            date: moment().toISOString(),
                            type: 'CREDITCARD'
                        }
                    },
                    booking.isPackage
                );
            } else {
                payment.setData(
                    (prev) => ({
                        ...prev,
                        paymentError: true,
                        errorDetails: response.data.errors
                    }),
                    isMounted
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            base.setIsLoading(false, isMounted);
        }
    };

    const checkFormErrors = (bookingStep: number) => {
        switch (bookingStep) {
            case 0:
                return !customerInfoStepController.isCustomerValid();
            case 1:
                return !participantInfoStepController.isParticipantInfoStepValid();
            default:
                return false;
        }
    };

    return createStore(BookingStore, children, {
        ...base,
        ...stepper,
        ...extrasStepController,
        ...participantInfoStepController,
        ...customerInfoStepController,
        ...pickUpStepController,
        ...manageBookingController,
        booking,
        packageBooking,
        checkFormErrors,
        completedBookings,
        setBooking,
        calcBookingTotal,
        handleQuantityChange,
        clearController,
        updateCardDetails,
        payment,
        processBooking
    });
};

import { Route, Routes } from 'react-router-dom';
import ProductTemplate from '../components/products/ProductTemplate';
import AgentPage from '../pages/AgentPage';
import BookingConfirmationPage from '../pages/BookingConfirmationPage';
import Contact from '../pages/Contact';
import Homepage from '../pages/Homepage';
import InTheAir from '../pages/InTheAir';
import ManageBooking from '../pages/ManageBooking';
import OnTheWater from '../pages/OnTheWater';
import Packages from '../pages/Packages';
import PageNotFound from '../pages/PageNotFound';
import TermsAndConditions from '../pages/TermsAndConditions';

export enum PATH_IDS {
    PRODUCT_NAME = 'productName'
}

export const routes = {
    products: (productName: string) => `/products/${productName}`
};

export default function Pages() {
    return (
        <Routes>
            <Route path={routes.products(':' + PATH_IDS.PRODUCT_NAME)} Component={ProductTemplate} />
            <Route path="/" Component={Homepage} />
            <Route path="/confirmation/:ref" Component={BookingConfirmationPage} />
            <Route path="/manage" Component={ManageBooking} />
            <Route path="/contact-us" Component={Contact} />
            <Route path="/terms-and-conditions" Component={TermsAndConditions} />
            <Route path="/onthewater" Component={OnTheWater} />
            <Route path="/scenic-flights" Component={InTheAir} />
            <Route path="/packages" Component={Packages} />
            <Route path="/agents" Component={AgentPage} />
            <Route path="/404" Component={PageNotFound} />
            <Route path="*" Component={PageNotFound} />
        </Routes>
    );
}

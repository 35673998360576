import { EventEmitter } from 'events';
import { ReactNode } from 'react';
import { SnackbarViewProps } from './SnackbarContainer';

class SnackbarManager extends EventEmitter {
    private props: SnackbarViewProps | undefined;

    private show (props: SnackbarViewProps) {
        this.props = props;
        this.emitChange();
        setTimeout(() => this.emit('info', undefined), props.timeout || 3000);
    }

    public warning = (message: ReactNode, timeout?: number) => this.show({ message, severity: 'warning', timeout });
    public success = (message: ReactNode, timeout?: number) => this.show({ message, severity: 'success', timeout });

    remove () {
        this.props = undefined;
        this.emitChange();
    }

    emitChange () {
        this.emit('info', this.props);
    }

    addChangeListener (callback: (props: SnackbarViewProps | undefined) => void) {
        this.addListener('info', callback);
    }
}

export default new SnackbarManager();

import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Box, Button, Container, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import { max } from 'lodash';
import { Fire } from 'mdi-material-ui';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { isOnSale, productURL } from '../../utils/functions/common';
import { Product, ProductCategories } from '../../utils/models/products';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { gradients } from '../../utils/theme';

const productGridStyles = makeStyles()((theme) => ({
    background: {
        paddingTop: 20,
        paddingBottom: 20
    },
    backgroundColor: {
        backgroundColor: theme.palette.background.subtle
    },
    gridContainer: {
        flexDirection: 'unset',
        height: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            flexDirection: 'row'
        }
    },
    popGrid: {
        padding: '10px 0',
        justifyContent: 'flex-start',
        [theme.breakpoints.only('md')]: {
            width: '90%',
            margin: 'auto'
        },
        [theme.breakpoints.only('sm')]: {
            maxWidth: 550,
            margin: 'auto'
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    imageCommon: {
        position: 'relative',
        overflow: 'hidden'
    },
    imageTop: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    imageLeft: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 0,
        height: '100%'
    },
    featureImage: {
        objectFit: 'cover',
        height: 260,
        width: '100%',
        '&:hover': {
            opacity: '0.8'
        },
        [theme.breakpoints.only('md')]: {
            height: '100%'
        },
        [theme.breakpoints.only('sm')]: {
            height: 310
        }
    },
    featureDescription: {
        position: 'relative',
        padding: '15px 15px 35px',
        textAlign: 'left',
        lineHeight: '1.1rem',
        [theme.breakpoints.up('lg')]: {
            minHeight: 260
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 200
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            paddingBottom: 15
        }
    },
    title: {
        fontSize: 28,
        color: 'rgb(0 0 0 / 80%)',
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        paddingBottom: 12,
        [theme.breakpoints.down('md')]: {
            fontSize: 22
        }
    },
    body: {
        fontSize: 15,
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 10
    },
    priceOffer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 20,
        marginLeft: 20,
        paddingBottom: 20,
        '& h5': {
            margin: 0,
            fontWeight: 500,
            color: 'rgb(0 0 0 / 80%)'
        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: 15,
            marginRight: 15,
            paddingBottom: 15
        }
    },
    featureButton: {
        width: 140,
        height: 42,
        background: gradients.circular,
        [theme.breakpoints.down('md')]: {
            width: 120
        }
    },
    onSale: {
        margin: '10px 10px 0 0',
        minWidth: 125,
        padding: '1px 14px 1px 8px',
        height: 40,
        background: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 15,
        fontWeight: 500,
        [theme.breakpoints.only('xs')]: {
            padding: '1px 12px 1px 6px',
            fontSize: 14,
            minWidth: 110,
            height: 37,
            margin: '10px 5px 0 0'
        }
    }
}));

export interface ProductGridProps {
    readonly backgroundColor?: boolean;
    readonly tag?: string;
    readonly category?: ProductCategories;
}

export default function ProductDisplayGrid({ backgroundColor, tag, category }: ProductGridProps) {
    const classes = productGridStyles().classes;
    const productStore = useContext(ProductsStore);
    const [widgetHeight, setWidgetHeight] = useState(0);
    const widgetRefs = useRef<any>([]);
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));

    const productsToDisplay = tag
        ? productStore.products.filter((prod) => prod.tags.includes(tag))
        : productStore.products.filter((prod) => prod.category === category);

    const numOfProds = productsToDisplay.length;

    const assignRef = (element: any, i: number) => {
        widgetRefs.current[i] = element;
    };

    const findMaxHeight = () => {
        const maxHeight = max(widgetRefs.current.map((ref: { offsetHeight: number }) => ref.offsetHeight));
        setWidgetHeight(maxHeight as number);
        return maxHeight;
    };

    useEffect(() => {
        findMaxHeight();
        window.addEventListener('resize', findMaxHeight);
        return () => {
            window.removeEventListener('resize', findMaxHeight);
        };
    }, []);

    useEffect(() => {
        findMaxHeight();
    }, [widgetRefs.current.map((r: any) => r.offsetHeight)]);

    return (
        <div className={clsx(classes.background, { [classes.backgroundColor]: backgroundColor })}>
            <Container maxWidth="lg">
                <Grid
                    container
                    className={classes.popGrid}
                    style={{ margin: 'auto', maxWidth: isLg && numOfProds < 5 && numOfProds % 2 === 0 && 1000 }}
                >
                    {productStore.isLoading
                        ? Array.from(Array(3).keys()).map((_, i) => (
                              <Grid
                                  container
                                  justifyContent="center"
                                  style={{ paddingBottom: 20 }}
                                  item
                                  key={`skel${i}`}
                                  xs={12}
                                  sm={6}
                                  md={4}
                              >
                                  <Skeleton variant="rectangular" width="90%" height={500} />
                              </Grid>
                          ))
                        : productsToDisplay.map((product: Product, i: number) => {
                              const linkURL = productURL(product.name);
                              return (
                                  <Grid
                                      key={product.productCode}
                                      item
                                      xs={12}
                                      lg={numOfProds % 3 === 0 ? 4 : numOfProds < 5 && numOfProds % 2 === 0 ? 12 : 4}
                                      style={{ padding: 10 }}
                                  >
                                      <Paper elevation={1} style={{ borderRadius: 10, height: '100%' }}>
                                          <Grid container className={classes.gridContainer}>
                                              <Grid
                                                  item
                                                  xs={12}
                                                  md={4}
                                                  lg={numOfProds < 5 && numOfProds % 2 === 0 ? 4 : 12}
                                                  style={{
                                                      height:
                                                          (isMd || (isLg && numOfProds < 5 && numOfProds % 2 === 0)) &&
                                                          (widgetHeight || 0) + 62
                                                  }}
                                              >
                                                  <Link to={linkURL}>
                                                      <div
                                                          style={{ position: 'relative' }}
                                                          className={clsx(classes.imageCommon, {
                                                              [classes.imageLeft]:
                                                                  isMd ||
                                                                  (isLg && numOfProds < 5 && numOfProds % 2 === 0),
                                                              [classes.imageTop]:
                                                                  !isMd &&
                                                                  !(isLg && numOfProds < 5 && numOfProds % 2 === 0)
                                                          })}
                                                      >
                                                          <img
                                                              className={classes.featureImage}
                                                              src={product?.photos?.[0]?.mediumURL}
                                                              alt={product?.photos?.[0]?.alt}
                                                              style={{
                                                                  height:
                                                                      isLg &&
                                                                      numOfProds < 5 &&
                                                                      numOfProds % 2 === 0 &&
                                                                      '100%'
                                                              }}
                                                          />
                                                      </div>
                                                  </Link>
                                              </Grid>
                                              <Grid
                                                  item
                                                  xs={true}
                                                  container
                                                  direction="column"
                                                  justifyContent="space-between"
                                              >
                                                  <Box style={{ height: widgetHeight || 0 }}>
                                                      <Grid
                                                          item
                                                          className={classes.featureDescription}
                                                          style={{
                                                              paddingBottom:
                                                                  isLg && numOfProds < 5 && numOfProds % 2 === 0 && 15,
                                                              minHeight:
                                                                  isLg &&
                                                                  numOfProds < 5 &&
                                                                  numOfProds % 2 === 0 &&
                                                                  'unset'
                                                          }}
                                                          ref={(el) => assignRef(el, i!)}
                                                      >
                                                          <Typography
                                                              variant="h5"
                                                              color="primary"
                                                              className={classes.title}
                                                          >
                                                              {product.name}
                                                          </Typography>
                                                          <Typography className={classes.body}>
                                                              {product.shortDescription}
                                                          </Typography>
                                                          <Box
                                                              component="div"
                                                              style={{ display: 'flex', flexWrap: 'wrap' }}
                                                          >
                                                              {isOnSale(product) && (
                                                                  <Box className={classes.onSale}>
                                                                      <LoyaltyIcon
                                                                          style={{ marginRight: 5, padding: 1 }}
                                                                      />
                                                                      On Sale!
                                                                  </Box>
                                                              )}
                                                              {product.saleTips &&
                                                                  product.saleTips.map((tip) => (
                                                                      <Box key={tip} className={classes.onSale}>
                                                                          <Fire
                                                                              style={{ marginRight: 5, padding: 1 }}
                                                                          />
                                                                          {tip}
                                                                      </Box>
                                                                  ))}
                                                          </Box>
                                                      </Grid>
                                                  </Box>
                                                  <Grid item className={classes.priceOffer}>
                                                      {isOnSale(product) ? (
                                                          <span style={{ display: 'flex', alignItems: 'center' }}>
                                                              <Typography
                                                                  variant="h5"
                                                                  style={{ textDecoration: 'line-through' }}
                                                              >
                                                                  ${product?.displayPrice?.price}
                                                              </Typography>
                                                              <Typography variant="h5" style={{ marginLeft: 10 }}>
                                                                  ${isOnSale(product)}
                                                              </Typography>
                                                          </span>
                                                      ) : (
                                                          <span style={{ display: 'flex', alignItems: 'center' }}>
                                                              <Typography variant="h5">
                                                                  ${product?.displayPrice?.price}
                                                              </Typography>
                                                          </span>
                                                      )}
                                                      <Link to={linkURL}>
                                                          <Button
                                                              variant="contained"
                                                              color="primary"
                                                              className={classes.featureButton}
                                                          >
                                                              Book Now
                                                          </Button>
                                                      </Link>
                                                  </Grid>
                                              </Grid>
                                          </Grid>
                                      </Paper>
                                  </Grid>
                              );
                          })}
                </Grid>
            </Container>
        </div>
    );
}

import { Box, Grid, Hidden, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useContext } from 'react';
import { BookingStore } from '../../utils/stores/BookingStore';
import { SummaryProps } from '../bookings/BookingFormSummary';
import ChangePickUp from './ChangePickUp';
import { ManageParticipantView } from './ParticipantDetails';
import { makeStyles } from 'tss-react/mui';

const summaryStyles = makeStyles()((theme) => ({
    paper: {
        padding: 15,
        paddingBottom: 8,
        borderRadius: 10,
        marginLeft: 10
    },
    detailContainer: {
        paddingLeft: 20
    },
    divider: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D9DBE9',
        width: '100%',
        height: 0
    },
    topDivider: {
        marginTop: 20,
        [theme.breakpoints.down('lg')]: {
            marginTop: 5
        }
    },
    itemText: {
        fontSize: 17,
        lineHeight: '110%',
        marginBottom: 12,
        marginTop: 12,
        fontWeight: 300
    },
    dateTimeText: {
        fontSize: 17,
        lineHeight: '110%',
        marginBottom: 5,
        fontWeight: 300
    },
    qtyText: {
        fontSize: 15,
        lineHeight: '110%',
        fontWeight: 300,
        opacity: 0.65
    }
}));

export const BookingInfo = ({ booking }: SummaryProps) => {
    const classes = summaryStyles().classes;
    const theme = useTheme();

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={12}>
                    <Hidden smDown>
                        <Typography
                            variant="h6"
                            style={{
                                paddingBottom: 0,
                                paddingTop: 10,
                                fontWeight: 400,
                                fontSize: 19
                            }}
                        >
                            Booking Details
                        </Typography>
                    </Hidden>
                    <Hidden smUp>
                        <Typography
                            className={classes.dateTimeText}
                            style={{ marginBottom: 10, fontWeight: 400, opacity: 0.85 }}
                        >
                            {booking.tour.productName}
                        </Typography>
                    </Hidden>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.dateTimeText}>Date of Travel</Typography>
                    <Typography className={classes.dateTimeText}>
                        {moment(booking.tour.startTimeLocal).utcOffset(10).format('D MMM YYYY')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.dateTimeText}>Tour Time</Typography>
                    <Typography className={classes.dateTimeText}>
                        {moment(booking.tour.startTimeLocal).utcOffset(10).format('h:mm a')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default function BookingDetailSection({ booking }: SummaryProps) {
    return (
        <>
            <BookingInfo booking={booking} />
        </>
    );
}

export const ManageBookingFields = () => {
    const bookingController = useContext(BookingStore);
    const participantFields = (bookingController.booking.participants || []).map((_, i) => (
        <ManageParticipantView key={`fare.id ${i + 1}`} participantIndex={i} />
    ));

    return (
        <Box style={{ paddingTop: 2, width: '100%' }}>
            {participantFields}
            <ChangePickUp />
        </Box>
    );
};

/* booking.extras.length > 0 && sum(booking.extras.map((e) => e.quantity)) > 0 && (
    <>
        <Grid item xs={12} style={{ paddingTop: 12 }}>
            <Typography variant="h6" style={{ fontWeight: 400, fontSize: 19 }}>
                Extras
            </Typography>
        </Grid>
        {booking.extras.map((extra, i) => (
            <Fragment key={`quantity${i}`}>
                {extra.quantity > 0 && (
                    <Grid container justifyContent="space-between" key={`quantity${i}`}>
                        <Grid item xs={9} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography className={classes.itemText} style={{ marginBottom: 3 }}>
                                {extra.name}
                            </Typography>
                            <Typography className={classes.qtyText}>
                                Quantity: {extra.quantity}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.itemText}>
                                ${' '}
                                {((extra.price * extra.quantity) / 100) % 1 !== 0
                                    ? amountUiStr((extra.price * extra.quantity) / 100)
                                    : (extra.price * extra.quantity) / 100}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Fragment>
        ))}
    </>
) */

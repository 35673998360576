import { Container, Typography } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { ProductCategories } from '../utils/models/products';
import { categoryStyles } from './OnTheWater';

export default function InTheAir() {
    const classes = categoryStyles().classes;

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Whitsunday Tours | Scenic Flights, Helicopter Tours &amp; Seaplane Tours</title>
                <meta
                    name="description"
                    content="Fly over the Whitsundays, with a range of scenic flights, helicopter tours, and seaplane tours departing Airlie Beach. Visit Whitehaven Beach, Hill Inlet, Heart Reef, the Great Barrier Reef, and Whitsunday Islands"
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="From the Air" tagline="See the Whitsundays and Great Barrier Reef from above" />
            </Container>
            <Container
                maxWidth="lg"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}
            >
                <Typography variant="h4" className={classes.title}>
                    Fly above it all
                </Typography>
                <Typography className={classes.body}>
                    Seeing the Whitsundays and Great Barrier Reef from above, should truly be on everyone&apos;s bucket
                    list. A range of flight products are available in the Whitsundays, including scenic flights,
                    helicopters, and seaplanes. Fly above it all, or land and explore, the choice is yours.
                </Typography>
            </Container>
            <ProductDisplayGrid category={ProductCategories.AIR} />
            <div style={{ marginBottom: 40, width: '100%' }} />
        </>
    );
}

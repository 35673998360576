import React, { useContext } from 'react';
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import DollarIcon from '../../assets/icons/dollarSign.svg';
import ClockIcon from '../../assets/icons/clockIcon.svg';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { getDurationStr } from '../../utils/functions/common';
import { widgetStyles } from './widgets/widgetStyles';
import { useTheme } from '@mui/material/styles';

export default function KeyDetailWidget() {
    const classes = widgetStyles;
    const productController = useContext(ProductsStore);
    const theme = useTheme();
    const mediumDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper
            variant="outlined"
            sx={{
                ...classes.paper,
                ...classes.paperLeft
            }}
        >
            <Grid container sx={{ ...classes.gridContainer }}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                    <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {productController.product?.priceOptions.length === 1 ? 'Price' : 'Prices'}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <img
                        src={DollarIcon}
                        alt={`${productController.product?.name} pricing`}
                        style={{ height: mediumDown ? 24 : 30 }}
                    />
                </Grid>
                <Grid item xs={10} sx={{ ...classes.gridItem }}>
                    {productController.product?.priceOptions.map((option) => (
                        <Grid container justifyContent="space-between" key={`${option.label} price`}>
                            <Grid item xs={9} style={{ paddingRight: 10 }}>
                                <Typography variant="h6" sx={{ ...classes.detailText }}>
                                    {option.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    key={`${option.label} price`}
                                    variant="h6"
                                    align="right"
                                    sx={{ ...classes.detailText }}
                                >
                                    $ {option.price}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="body2" sx={{ opacity: 0.8, marginTop: 1.5 }}>
                    Prices may differ, based on the departure date &amp; time. Please check the final price once you
                    commence a booking.
                </Typography>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                    <Typography variant="h5" style={{ fontWeight: 500 }}>
                        Duration
                    </Typography>
                </Grid>
                <Grid container alignContent="center">
                    <Grid item xs={2}>
                        <img
                            src={ClockIcon}
                            alt={`${productController.product?.name} duration`}
                            style={{ height: mediumDown ? 24 : 30 }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h6" sx={{ ...classes.detailText }}>
                            {productController.product?.duration
                                ? getDurationStr(productController.product.duration)
                                : 'No Set Duration'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

import { isEmpty, startCase } from 'lodash';
import { ChangeEvent } from 'react';
import { Booking } from '../../models/bookings';
import IsMountedWrapper from '../../functions/isMountedWrapper';
import { State, StateSetterType } from '../../functions/hooks';
import { WrappedSetter } from '../BaseStore';
import moment from 'moment';
import { AvailabilityValue } from '../ProductsStore';

interface CustomerErrors {
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email?: string;
    readonly phone?: string;
    readonly packageDate?: string;
}

export interface CustomerInfoStepControllerValue {
    readonly customerErrors: CustomerErrors;
    readonly setCustomerErrors: StateSetterType<{}>;
    readonly handleCustomerChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    readonly isCustomerValid: () => boolean;
}

export const setOrDelete = (object: any, key: any, value?: any) => {
    const tmp = { ...object };
    if (value) {
        tmp[key] = value;
    } else {
        delete tmp[key];
    }
    return tmp;
};

export default function CustomerInfoStepController (
    setBooking: WrappedSetter<Booking>,
    booking: Booking,
    packageBooking: Booking,
    availability: AvailabilityValue
): CustomerInfoStepControllerValue {
    const isMounted = IsMountedWrapper();
    const customerErrors = State<{}>([], isMounted);

    const handleCustomerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const key = event.target.name;
        const value = key === 'firstName' || key === 'lastName' ? startCase(event.target.value) : event.target.value;
        customerErrors.set((prev) => setOrDelete(prev, key, undefined));
        setBooking((prev) => ({ ...prev, customer: { ...prev.customer, [key]: value } }), isMounted);
    };

    const isPackageDateError = () => {
        if (!booking.isPackage) {
            return undefined;
        } else if (moment(booking.tour.startTimeLocal).isSame(packageBooking.tour.startTimeLocal, 'day')) {
            return 'Both products cannot be on the same day';
        } else if (
            !availability.packageSessions.find((s) => moment(s.startTime).isSame(packageBooking.tour.startTimeLocal))
        ) {
            return 'Check your departure time';
        } else return undefined;
    };

    const isCustomerValid = () => {
        let error = setOrDelete({}, 'firstName', isFirstNameValid(booking.customer.firstName));
        error = setOrDelete(error, 'lastName', isLastNameValid(booking.customer.lastName));
        error = setOrDelete(error, 'email', isValidEmail(booking.customer.email));
        error = setOrDelete(error, 'phone', isValidPhone(booking.customer.phone));
        error = setOrDelete(error, 'packageDate', isPackageDateError());
        customerErrors.set(error);
        return isEmpty(error);
    };

    return {
        customerErrors: customerErrors.data,
        setCustomerErrors: customerErrors.set,
        handleCustomerChange,
        isCustomerValid
    };
}

export const isFirstNameValid = (firstName?: string) => (isEmpty(firstName) ? 'First name is required' : undefined);

export const isLastNameValid = (lastName?: string) => (isEmpty(lastName) ? 'Last name is required' : undefined);

export const isValidEmail = (email?: string): string | undefined => {
    if (!email) {
        return 'Please enter your email address';
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return 'Invalid email address';
    }
};

export const isValidPhone = (phone?: string) => (isEmpty(phone) ? 'Phone number is required' : undefined);

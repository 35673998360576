import { floor, lowerCase, remove } from 'lodash';
import moment from 'moment';
import { Booking, Customer, Participant, ParticipantInfoField, ParticipantInfoFieldType } from '../models/bookings';
import { ApplyExtras, Extras, PriceOption, Product } from '../models/products';
import {
    RezdyBooking,
    RezdyBookingItem,
    RezdyExtra,
    RezdyImage,
    RezdyProduct,
    RezdyQuantity
} from '../models/rezdyObjects';
import { GSLEntities } from './../models/bookings';
import { convertToSearchableName } from './common';

const convertRezdyImage = (productName: string, image: RezdyImage, i: number) => {
    const newImage = {
        thumbnailURL: image.thumbnailUrl ? image.thumbnailUrl : undefined,
        mediumURL: image.mediumSizeUrl,
        largeURL: image.largeSizeUrl,
        url: image.itemUrl,
        alt: `${productName} - Whitsunday Day Trip Airlie Beach ${i + 1}`
    };
    return newImage;
};

const convertListOptions = (listOptions?: string): string[] => (listOptions ? listOptions?.split('\r\n') : []);

const convertExtraToParseportsType = (rezdyExtra: RezdyExtra): Extras => ({
    id: rezdyExtra.name,
    name: rezdyExtra?.name || '',
    description: rezdyExtra?.description || '',
    image: rezdyExtra?.image && {
        url: rezdyExtra.image.itemUrl,
        thumbnailURL: rezdyExtra.image.thumbnailUrl,
        alt: rezdyExtra.name
    },
    price: floor(100 * (rezdyExtra?.price || 0)),
    appliesTo: rezdyExtra?.extraPriceType !== 'QUANTITY' ? ApplyExtras.PER_BOOKING : ApplyExtras.PER_PERSON
});

const sortFares = (originalProduct: Product, rezdyProduct: RezdyProduct) => {
    const tempFares = originalProduct.excludeFares
        ? remove(rezdyProduct.priceOptions, (option) => {
              return !originalProduct.excludeFares.includes(option.label);
          })
        : rezdyProduct.priceOptions;
    return tempFares.map((option) => ({
        label: option.label,
        price: option.price,
        seatsUsed: option.seatsUsed
    })) as PriceOption[];
};

export const convertRezdyProduct = (originalProduct: Product, rezdyProduct: RezdyProduct) => {
    const participantInfoFields = rezdyProduct?.bookingFields
        .filter((bookingField: any) => bookingField && bookingField.requiredPerParticipant)
        .reduce((acc: ParticipantInfoField[], bookingField: any) => {
            const convertedType = lowerCase(bookingField.fieldType);
            const participantInfoField: any = {
                label: bookingField.label,
                value: bookingField.value,
                fieldType: convertedType === 'string' ? ParticipantInfoFieldType.TEXT : convertedType
            };
            if (convertedType === ParticipantInfoFieldType.LIST) {
                participantInfoField.listOptions = convertListOptions(bookingField.listOptions);
            }
            return acc.concat(participantInfoField);
        }, []) as any;
    const tempObject: Product = rezdyProduct && {
        ...originalProduct,
        productCode: rezdyProduct.productCode,
        name: originalProduct.name !== '' ? originalProduct.name : rezdyProduct.name,
        cancellationPeriod: originalProduct.cancellationPeriod
            ? originalProduct.cancellationPeriod
            : rezdyProduct.cancellationPolicyDays
              ? rezdyProduct.cancellationPolicyDays
              : 1,
        maxQuantity: rezdyProduct.quantityRequiredMax,
        searchableName:
            originalProduct.name !== ''
                ? convertToSearchableName(originalProduct.name)
                : convertToSearchableName(rezdyProduct.name),
        shortDescription:
            originalProduct.shortDescription !== '' ? originalProduct.shortDescription : rezdyProduct.shortDescription,
        longDescription:
            originalProduct.longDescription !== '' ? originalProduct.longDescription : rezdyProduct.description,
        duration: rezdyProduct.durationMinutes,
        participantInfoFields: participantInfoFields,
        photos:
            rezdyProduct.images && rezdyProduct.images.map((img, i) => convertRezdyImage(rezdyProduct.name, img, i)),
        priceOptions: sortFares(originalProduct, rezdyProduct),
        displayPrice: {
            label: undefined,
            price: rezdyProduct.advertisedPrice
        },
        // If you want to stop the extras displaying on this site, just make this an empty array on productCodes
        extras: originalProduct.extras
            ? originalProduct.extras
            : (rezdyProduct.extras || []).map(convertExtraToParseportsType),
        minimumNoticeMinutes: rezdyProduct.minimumNoticeMinutes,
        pickupID: rezdyProduct.pickupId
    };
    return tempObject;
};

const participantsToRezdyParticipants = (participant: Participant, product: Product) => {
    const filterFields = participant.fields.filter((field) =>
        product.participantInfoFields.find((f) => f.label === field.label)
    );
    if (filterFields.length > 0) {
        return {
            fields: filterFields.map((field) => ({
                label: field.label,
                value: field.value
            }))
        };
    } else return undefined;
};

const extraToRezdyExtra = (extras: Extras[]) => {
    const selectedExtras = extras.filter((ex) => ex.quantity > 0);
    return selectedExtras.map((ext) => ({
        name: ext.name,
        price: floor((ext.price || 0) / 100, 2),
        quantity: ext.quantity
    }));
};

export const convertToRezdyBooking = (booking: Booking, product: Product) => {
    const updatedParticipants = booking.participants.map((part) => participantsToRezdyParticipants(part, product));
    const rezBooking: RezdyBooking = {
        comments: booking.comment,
        customer: booking.customer,
        items: [
            {
                startTime: moment(booking.tour.startTimeLocal).toISOString(),
                extras: booking.extras && booking.extras.length > 0 ? extraToRezdyExtra(booking.extras) : undefined,
                participants: updatedParticipants[0] === undefined ? undefined : updatedParticipants,
                pickupLocation: {
                    locationName: booking.tour.pickUpLocation
                },
                productCode: booking.tour.productCode,
                quantities: booking.quantities.map((quan) => {
                    const quantityObject: RezdyQuantity = {
                        optionLabel: quan.label,
                        optionPrice: quan.price,
                        value: quan.value
                    };
                    return quantityObject;
                })
            }
        ],
        payments: [
            {
                amount: booking.payment.amount,
                type: booking.payment.type,
                date: booking.payment.date,
                label: `EWAY: ${booking.payment.paymentId}`
            }
        ],
        sendNotifications: false,
        source: 'API',
        status: 'CONFIRMED',
        internalNotes: booking.comment,
        totalAmount: booking.totalAmount
    };
    return rezBooking;
};

export const createRezdyPackageBooking = (
    booking: Booking,
    packageBooking: Booking,
    product: Product,
    packageProduct: Product
) => {
    const updatedParticipants = booking.participants.map((part) =>
        participantsToRezdyParticipants(part, packageProduct)
    );
    const rezBooking: RezdyBooking = {
        comments: `Booking created by ${product.entity}. Package booking, payment taken with lead booking ref ${booking.bookingResponse.refNum}`,
        customer: packageBooking.customer,
        items: [
            {
                startTime: moment(packageBooking.tour.startTimeLocal).toISOString(),
                participants: updatedParticipants,
                pickupLocation: {
                    locationName: packageBooking.tour.pickUpLocation
                },
                productCode: packageProduct.productCode,
                quantities: packageBooking.quantities.map((quan) => {
                    const quantityObject: RezdyQuantity = {
                        optionLabel: quan.label,
                        optionPrice: quan.price,
                        value: quan.value
                    };
                    return quantityObject;
                })
            }
        ],
        payments: [
            {
                amount: 0,
                type: 'INVOICE',
                date: booking.payment.date
            }
        ],
        sendNotifications: false,
        source: 'API',
        status: 'CONFIRMED',
        internalNotes: `Booking created by ${product.entity}. Package booking, payment taken with lead booking ref ${booking.bookingResponse.refNum}`,
        totalAmount: 0
    };
    return rezBooking;
};

export const convertFromRezdyBooking = (products: Product[], rezdyBooking: RezdyBooking) => {
    const createParticipants = (item: RezdyBookingItem) => {
        const product = products.find((p) => p.productCode === item.productCode);
        const participants = item.participants.map((p) => ({
            fields: p.fields.map((f) => ({
                label: f.label,
                value: f.value,
                fieldType: (product.participantInfoFields || []).find((field) => field.label === f.label)?.fieldType
            }))
        }));
        return participants;
    };

    const customer: Customer = {
        firstName: rezdyBooking.customer.firstName,
        lastName: rezdyBooking.customer.lastName,
        email: rezdyBooking.customer.email,
        phone: rezdyBooking.customer.phone
    };
    const entity = {
        140470: GSLEntities.AVIATION,
        153744: GSLEntities.MARINE,
        186616: GSLEntities.SEAPLANES
    };
    const operatorRef = rezdyBooking.orderNumber;
    const totalAmount = rezdyBooking.totalAmount;
    const bookings: Booking[] = rezdyBooking.items.map((item) => ({
        customer: customer,
        entity: entity[rezdyBooking.supplierId],
        operatorRef: operatorRef,
        totalAmount: totalAmount,
        participants: createParticipants(item),
        quantities: item.quantities.map((q) => ({
            label: q.optionLabel,
            value: q.value,
            price: q.optionPrice
        })),
        bookingComplete: true,
        tour: {
            pickUpLocation: item?.pickupLocation?.locationName,
            pickUpTime: item?.pickupLocation?.pickupTime || '',
            productCode: item.productCode,
            productName: item.productName,
            startTimeLocal: moment(item.startTime).utcOffset(10).toISOString(true)
        },
        extras: []
    }));
    return bookings;
};

export const createUpdatedBooking = (originalBooking: RezdyBooking, bookings: Booking[]) => {
    const rezdyBooking: RezdyBooking = {
        ...originalBooking,
        status: 'CONFIRMED',
        items: bookings.map((booking, i) => ({
            ...originalBooking.items[i],
            participants: booking.participants.map((p) => ({
                fields: p.fields.map((f) => ({
                    label: f.label,
                    value: f.value
                }))
            })),
            pickupLocation: {
                locationName: booking.tour.pickUpLocation
            }
        }))
    };
    return rezdyBooking;
};

import { Grid } from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { sum } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import { gradients } from '../../../utils/theme';

const availabilityStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        [theme.breakpoints.only('xs')]: {
            padding: '0 20px'
        }
    },
    paper: {
        zIndex: 8,
        padding: '15px 25px 20px',
        width: '80%',
        '@media (max-width:999px)': {
            width: '100%'
        },
        borderRadius: 10,
        [theme.breakpoints.only('sm')]: {
            padding: '5px 10px 30px'
        },
        [theme.breakpoints.only('xs')]: {
            zIndex: 5,
            padding: 0
        }
    },
    menu: {
        width: 190
    },
    menuButton: {
        width: '90%',
        height: 54,
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 14.5,
        paddingRight: 14.5,
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    },
    menuItem: {
        width: 280,
        paddingRight: 2
    },
    loadContainer: {
        width: '90%',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        height: 54,
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderStyle: 'solid',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgb(0 0 0 / 23%)'
    },
    title: {
        marginBottom: 15,
        width: '100%',
        textAlign: 'left',
        fontSize: 22,
        backgroundImage: gradients.main,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        [theme.breakpoints.down('md')]: {
            paddingTop: 15
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '5%'
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: '5%'
        }
    },
    buttonContainer: {
        justifyContent: 'center',
        alignContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'center',
            marginTop: 25
        }
    },
    button: {
        width: '80%',
        height: 54,
        backgroundImage: gradients.circular,
        [theme.breakpoints.down('md')]: {
            width: '90%'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '30px 0'
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    innerContainer: {
        [theme.breakpoints.only('xs')]: {
            alignItems: 'center',
            flexDirection: 'column'
        }
    },
    sectionItem: {
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignText: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            width: '90%'
        }
    },
    packageNote: {
        width: '100%',
        display: 'flex',
        marginTop: 20,
        [theme.breakpoints.only('sm')]: {
            padding: '0 2.5%'
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: 15,
            justifyContent: 'center',
            padding: 0
        }
    },
    packageNoteText: {
        color: theme.palette.text.soft,
        fontWeight: 400,
        fontSize: 17,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.3,
        paddingRight: '5%',
        [theme.breakpoints.only('xs')]: {
            paddingRight: 0
        }
    },
    calendarSize: {
        '& .MuiPopover-paper': {
            [theme.breakpoints.only('xs')]: {
                maxWidth: 'calc(100% - 10px)',
                overflowX: 'scroll',
                color: 'pink'
            }
        }
    },
    innerGridItem: {
        width: '100%',
        [theme.breakpoints.only('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));

interface CalendarDateSelProps {
    readonly isPackage?: boolean;
}

export default function CalendarDateSel({ isPackage }: CalendarDateSelProps) {
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const isMounted = IsMountedWrapper();
    const classes = availabilityStyles().classes;

    const handleDateChange = (date: any) => {
        isPackage
            ? productController.setAvailability(
                  (prev) => ({
                      ...prev,
                      packageDepartureDate: moment(date).utcOffset(10).toISOString(true)
                  }),
                  isMounted
              )
            : productController.setAvailability(
                  (prev) => ({
                      ...prev,
                      departureDate: moment(date).utcOffset(10).toISOString(true)
                  }),
                  isMounted
              );
    };

    useEffect(() => {
        productController.getAvailability();
    }, [
        isPackage ? productController.availability.packageDepartureDate : productController.availability.departureDate
    ]);

    useEffect(() => {
        productController.getAvailability();
        handleDateChange(
            isPackage
                ? moment(productController.availability.packageDepartureDate).isAfter(
                      moment().add(productController.packageProduct.data.minimumNoticeMinutes, 'minutes')
                  )
                    ? productController.availability.packageDepartureDate
                    : productController.availability.packageSessions.find(
                          (sesh) =>
                              moment(sesh.startTime).isAfter(
                                  moment().add(productController.packageProduct.data.minimumNoticeMinutes, 'minutes')
                              ) &&
                              sesh.seatsRemaining >=
                                  sum(
                                      bookingController.packageBooking.data.quantities.map((q) => q.seatsUsed * q.value)
                                  ) &&
                              sesh
                      )?.startTime // If there's never a time available, maybe we can handle this better with message on client side?
                : moment()
        );
    }, []);

    const [error, setError] = useState<DateValidationError | null>(null);

    return (
        <Grid item xs={12} className={classes.sectionItem}>
            <DatePicker
                label="Select Date"
                onError={(err) => setError(err)}
                slotProps={{
                    textField: {
                        helperText: error && bookingController.customerErrors.packageDate
                    }
                }}
                shouldDisableDate={(date) => {
                    return isPackage && moment(date).isSame(bookingController.booking.tour.startTimeLocal, 'day');
                }}
                value={
                    isPackage
                        ? moment(productController.availability.packageDepartureDate).utcOffset(10)
                        : moment(productController.availability.departureDate).utcOffset(10)
                }
                onChange={handleDateChange}
                onAccept={handleDateChange}
                minDate={moment()}
                sx={{ width: { xs: 1, md: isPackage ? 1 : 0.9 }, height: '54px' }}
                format="DD MMM YYYY"
            />
        </Grid>
    );
}

import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import { commonStyles } from './commonStyles';
import SelectPickUp from './SelectPickUp';

export default function PickUpSection() {
    const classes = commonStyles().classes;
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const pickUpExists =
        productController.product?.pickupList &&
        productController.product.pickupList.slice(1, productController.product.pickupList.length).length > 0;
    const packagePickUpExists =
        productController.packageProduct.data?.pickupList &&
        productController.packageProduct.data.pickupList.slice(
            1,
            productController.packageProduct.data.pickupList.length
        ).length > 0;

    useEffect(() => {
        !pickUpExists && bookingController.resetPickUp();
        !packagePickUpExists && bookingController.resetPackagePickUp();
        return () => {
            pickUpExists && !bookingController.needPickUp && bookingController.resetPickUp();
            packagePickUpExists && !bookingController.needPackagePickUp && bookingController.resetPackagePickUp();
        };
    }, []);

    return (
        <Grid container className={classes.container}>
            <Typography variant="h5" className={classes.title}>
                {pickUpExists ? 'Transport & Check-In' : 'Check-In'}
            </Typography>
            <Typography className={classes.subtitle} style={{ paddingBottom: 10 }}>
                {pickUpExists
                    ? "Please select your transport option below. If you change your mind later on, that's cool, just get in touch with us and we'll update the booking for you."
                    : "Alright, now we just need to make sure that you're in the right place, at the right time."}
            </Typography>
            <Typography className={classes.subtitle}>
                We&apos;ll also include this info in your confirmation email.
            </Typography>
            {productController.product?.allowPackage && (
                <Typography variant="h6" style={{ marginBottom: 20, opacity: 0.9 }}>
                    First, let&apos;s organise the {bookingController.booking.packageProductNames[0]}
                </Typography>
            )}
            <SelectPickUp />
            {productController.product?.allowPackage && (
                <>
                    <Grid container direction="column">
                        <Typography variant="h6" style={{ margin: '15px 0 20px', opacity: 0.9 }}>
                            Next, your {bookingController.booking.packageProductNames[1]}
                        </Typography>
                    </Grid>
                    <SelectPickUp isPackage={true} />
                </>
            )}
        </Grid>
    );
}

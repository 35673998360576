import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import axios from 'axios';
import { flatten, shuffle } from 'lodash';
import { useEffect, useState } from 'react';
import InstagramImageGallery from './InstagramImageGallery';

interface InstagramGridProps {
    readonly backgroundColor?: boolean;
}

export default function InstagramImages({ backgroundColor }: InstagramGridProps) {
    const [mediaList, setMediaList] = useState([]);
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const path = process.env.NODE_ENV === 'production' ? 'https://gslwhitsundays.com.au/' : 'http://localhost:5000/';

    const getMediaList = async () => {
        try {
            const res1 = await axios(`${path}instagram/images?entity=gslAviation`);
            const res2 = await axios(`${path}instagram/images?entity=gslMarine`);
            Promise.allSettled([res1, res2]).then((results) => {
                const settled = results.map((r) => r.status === 'fulfilled' && r.value);
                const imageArray = flatten(settled.map((r) => r.data));
                imageArray.splice(-1);
                const detailedList = imageArray.map((image, i) => ({
                    url: image,
                    alt: `Airlie Beach Tour booking. Whitehaven and Great Barrier Reef - image ${i + 1}`
                }));
                setMediaList(shuffle(detailedList));
            });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getMediaList();
    }, []);

    const BodyText = styled(Typography)(() => ({
        fontSize: 16,
        lineHeight: '140%',
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 20,
        paddingTop: 10
    }));

    return (
        <Box sx={{ backgroundColor: (theme) => backgroundColor && theme.palette.background.subtle, width: 1 }}>
            <Container maxWidth="lg" sx={{ p: xsScreen && 0 }}>
                <Grid
                    container
                    sx={{
                        margin: 'auto',
                        pt: { xs: 0, sm: 3 },
                        pb: { xs: 0, sm: 3 },
                        justifyContent: 'center'
                    }}
                >
                    <Grid
                        container
                        sx={{ textAlign: 'center', px: { xs: 0, md: 1 }, py: { xs: 1, md: 0, lg: 1 } }}
                        item
                        xs={12}
                        md={5}
                        lg={6}
                    >
                        <Box sx={{ maxWidth: { md: 700 }, width: '100%', height: '100%' }}>
                            <InstagramImageGallery images={mediaList} />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        lg={6}
                        sx={{
                            py: { xs: 2, sm: 2, md: 0, lg: 2 },
                            px: { xs: 2, sm: 0, md: 2 },
                            justifyContent: { xs: 'unset', md: 'center', lg: 'unset' },
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '700px'
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontSize: { xs: 22, lg: 28 },
                                color: (theme) => theme.palette.primary.dark,
                                lineHeight: '115%',
                                letterSpacing: '-1px',
                                fontWeight: 400,
                                paddingBottom: 1.5
                            }}
                        >
                            The Beautiful Whitsundays
                        </Typography>
                        <BodyText>
                            The 74 Islands of The Whitsundays are world-reknown for their natural beauty. Consisting
                            mostly of National Parks, the islands feature amazing landscapes of rainforests, beaches,
                            and unique eco-systems, surrounded by the Great Barrier Reef Marine Park. Beyond the
                            Whitsunday Islands, is the Great Barrier Reef. Approximately 80km offshore, the closest
                            sections of Reef to Airlie Beach are Hardy Reef, Hook Reef, and Bait Reef.
                        </BodyText>
                        <BodyText sx={{ display: { xs: 'block', md: 'none', lg: 'block' } }}>
                            Book with GSL Whitsundays, and access the best tours and experiences in Airlie Beach. Travel
                            to the Great Barrier Reef, Whitehaven Beach, and all of the best sights of the Whitsundays.
                            Our shop is located in the Heart of Airlie Beach, and open 7 days a week. Book with us
                            online, knowing that we&apos;re also here in person, and located locally in Airlie Beach.
                        </BodyText>

                        <BodyText>
                            Multiple tours depart daily from Airlie Beach, with a range of experiences in the
                            Whitsundays including Whitehaven Beach, and the Great Barrier Reef. Come and explore our
                            amazing backyard!
                        </BodyText>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

/*

Collate and shuffle all product images from Product Store

const productController = useContext(ProductsStore);
const mediaList = shuffle(
    productController.products.map((prod) => prod.photos).reduce((acc, photos) => acc.concat(photos))
).map((image) => image.largeURL);

*/

import axios from 'axios';
import { isEmpty } from 'lodash';
import { WrappedUseStateReturn, WrappedUseState } from '../../functions/hooks';
import IsMountedWrapper from '../../functions/isMountedWrapper';
import { convertFromRezdyBooking, createUpdatedBooking } from '../../functions/rezdy';
import { Booking, EMPTY_BOOKING } from '../../models/bookings';
import { Product } from '../../models/products';
import { RezdyBooking } from '../../models/rezdyObjects';
import { BaseStoreValues, WrappedSetter } from '../BaseStore';

export interface ManageBookingControllerValue {
    readonly rezdyResults: WrappedUseStateReturn<Booking[] | undefined>;
    readonly findBooking: (ref: string, surname: string) => void;
    readonly updateBooking: () => void;
    readonly originalBooking: WrappedUseStateReturn<RezdyBooking | undefined>;
    readonly newBooking: () => RezdyBooking;
}

export default function ManageBookingController(
    setBooking: WrappedSetter<Booking>,
    booking: Booking,
    base: BaseStoreValues<Booking>,
    apiURL: string,
    products: Product[]
): ManageBookingControllerValue {
    const isMounted = IsMountedWrapper();
    const rezdyBooking = WrappedUseState<RezdyBooking | undefined>(undefined);
    const rezdyResults = WrappedUseState<Booking[] | undefined>(undefined);

    const findBooking = async (ref: string, surname: string) => {
        base.setIsLoading(true, isMounted);
        rezdyResults.setData(undefined, isMounted);
        rezdyBooking.setData(undefined, isMounted);
        setBooking(EMPTY_BOOKING, isMounted);
        try {
            const response = await axios(`${apiURL}rezdy/find?ref=${ref}&surname=${surname.toLowerCase()}`);
            if (!isEmpty(response.data)) {
                rezdyBooking.setData(response.data, isMounted);
                const bookingResults = !isEmpty(response.data) && convertFromRezdyBooking(products, response.data);
                rezdyResults.setData(bookingResults, isMounted);
                setBooking(bookingResults[0], isMounted);
            }
        } catch (e) {
            console.error(e);
        } finally {
            base.setIsLoading(false, isMounted);
        }
    };

    const newBooking = () => {
        const index = rezdyResults.data.findIndex((b) => b.tour.startTimeLocal === booking.tour.startTimeLocal);
        const tempBookingArray = rezdyResults.data;
        tempBookingArray.splice(index, 1, booking);
        const rezBooking = createUpdatedBooking(rezdyBooking.data, tempBookingArray);
        return rezBooking;
    };

    const updateBooking = async () => {
        base.setIsLoading(true, isMounted);
        try {
            await axios.post(`${apiURL}rezdy/update`, newBooking());
        } catch (e) {
            console.error(e);
        } finally {
            base.setIsLoading(false, isMounted);
        }
    };

    return {
        rezdyResults: rezdyResults,
        findBooking: findBooking,
        updateBooking: updateBooking,
        originalBooking: rezdyBooking,
        newBooking: newBooking
    };
}

import DateRangeIcon from '@mui/icons-material/DateRange';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {
    Box,
    Grid,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import parse, { DOMNode, Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { Sale } from '../../utils/models/products';

const useStyles = makeStyles()((theme) => ({
    priceDisplay: {
        marginBottom: '20px',
        '& .MuiTypography-h5': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            textAlign: 'center',
            borderStyle: 'solid',
            borderRadius: '20px',
            fontStyle: 'normal',
            width: '170px',
            lineHeight: '2.2rem',
            fontSize: '1.3rem'
        }
    },
    salesSection: {
        padding: '0 15px 30px',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 'calc(80% + 30px)'
        },
        [theme.breakpoints.only('sm')]: {
            padding: '0 16px 8px 16px'
        },
        [theme.breakpoints.only('xs')]: {
            padding: '10px 16px 10px'
        }
    },
    salesPaper: {
        width: '100%',
        borderRadius: 10,
        [theme.breakpoints.only('xs')]: {
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '15px 15px 5px'
        }
    },
    titleSection: {
        background: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.primary.light}`,
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        borderLeft: `1px solid ${theme.palette.primary.light}`,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        [theme.breakpoints.only('xs')]: {
            border: 'none',
            justifyContent: 'flex-start',
            paddingBottom: 5,
            background: 'unset'
        }
    },
    textSection: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        padding: '5px 15px 15px',
        [theme.breakpoints.only('xs')]: {
            border: 'none',
            padding: '5px 0 10px'
        }
    },
    saleFareSection: {
        padding: '0 10px',
        display: 'flex',
        color: theme.palette.text.soft,
        flexWrap: 'nowrap',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0
        }
    },
    descriptionText: {
        textAlign: 'justify',
        color: theme.palette.text.soft,
        marginBottom: 15,
        [theme.breakpoints.only('xs')]: {
            lineHeight: 1.35,
            marginBottom: 10
        }
    },
    descriptionBold: {
        color: theme.palette.text.primary,
        fontWeight: 500
    },
    list: {
        paddingTop: 0
    },
    listText: {
        color: theme.palette.text.soft,
        fontSize: '1rem',
        paddingTop: 2,
        paddingBottom: 2
    },
    boldOverride: {
        marginBottom: 10,
        '& strong, b': {
            fontWeight: 500,
            marginBottom: 5
        }
    },
    boldOverrideP: {
        marginTop: 30,
        color: theme.palette.text.primary,
        '& strong, b': {
            fontWeight: 500
        }
    }
}));

interface NodeText {
    nodeValue: string;
}

export const DisplayText = (props: { text: string }) => {
    const classes = useStyles().classes;

    const formatParagraph = (text: string) => {
        return text.replace(/(?:<br>)/g, '');
    };

    const htmlNoBreaks = formatParagraph(props.text);

    const options: HTMLReactParserOptions = {
        replace: (domNode) => {
            if (domNode.nodeType === 3 && !domNode.parent) {
                const newNode: any = domNode;
                return <Typography className={classes.descriptionText}>{newNode.data}</Typography>;
            } else if (domNode instanceof Element) {
                if (domNode?.name === 'ul' || domNode?.name === 'ol') {
                    return <List className={classes.list}>{domToReact(domNode.children as DOMNode[], options)}</List>;
                } else if (domNode?.name === 'li') {
                    return (
                        <ListItem className={classes.listText}>
                            <ListItemIcon style={{ minWidth: 30 }}>•</ListItemIcon>
                            <ListItemText>{domToReact(domNode.children as DOMNode[], options)}</ListItemText>
                        </ListItem>
                    );
                } else if (domNode.name === 'p') {
                    const bold = domNode.children
                        .map((child: Element) => child.name === 'b' || child.name === 'strong')
                        .includes(true);
                    return (
                        <>
                            <Typography className={clsx(classes.descriptionText, { [classes.boldOverrideP]: bold })}>
                                {domToReact(domNode.children as DOMNode[], options)}
                                {bold && <br />}
                            </Typography>
                        </>
                    );
                } else if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(domNode.name) !== -1) {
                    const bold = domNode.children
                        .map((child: Element) => child.name === 'b' || child.name === 'strong')
                        .includes(true);
                    return (
                        <Typography variant="h6" className={clsx({ [classes.boldOverride]: bold })}>
                            {domToReact(domNode.children as DOMNode[], options)}
                        </Typography>
                    );
                } else if (domNode.name === 'strong' && !domNode.parent) {
                    return (
                        <>
                            <Typography
                                className={clsx(classes.descriptionText, classes.boldOverrideP)}
                                style={{ fontWeight: 500 }}
                            >
                                {domToReact(domNode.children as DOMNode[], options)}
                            </Typography>
                            <br />
                        </>
                    );
                }
            }
        }
    };
    const stripedText = parse(htmlNoBreaks, options);

    return (
        <>
            <Box style={{ marginTop: 10 }}>{stripedText}</Box>
        </>
    );
};

interface SaleDisplayProps {
    readonly currentSales: () => Sale[];
}

export const Sales = ({ currentSales }: SaleDisplayProps) => {
    const classes = useStyles().classes;
    const sales = currentSales();
    const theme = useTheme();
    const smallerSize = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <Grid container className={classes.salesSection}>
            <Box className={classes.salesPaper}>
                <Grid container>
                    <Grid item xs={12} sm={4} className={classes.titleSection}>
                        <NewReleasesIcon
                            style={{ color: xs ? theme.palette.primary.main : 'white', marginRight: 10 }}
                            fontSize="large"
                        />
                        <Typography
                            display="inline"
                            variant="h5"
                            style={{
                                fontSize: smallerSize ? 24 : 28,
                                fontWeight: 400,
                                color: xs ? theme.palette.primary.main : 'white'
                            }}
                        >
                            On Sale
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.textSection}>
                        <Grid container>
                            {sales.map((sale, i) => (
                                <Grid container key={`saleItem${i + 1}`}>
                                    <Hidden only="xs">
                                        <Grid item xs="auto">
                                            <DateRangeIcon
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: 10
                                                }}
                                            />
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={true}>
                                        <Typography
                                            style={{
                                                fontWeight: 500,
                                                fontSize: 18,
                                                paddingTop: 10
                                            }}
                                        >
                                            {moment(sale.startDate).format('Do MMMM')} -{' '}
                                            {moment(sale.endDate).format('Do MMMM')}
                                        </Typography>
                                    </Grid>
                                    <Grid container style={{ marginTop: 10 }}>
                                        <Grid item xs={12} sm="auto">
                                            {sale.fareTypes.map((fare, i) => (
                                                <Grid
                                                    container
                                                    key={`saleFare${i + 1}`}
                                                    className={classes.saleFareSection}
                                                >
                                                    <Grid item xs="auto">
                                                        <Typography style={{ fontSize: 17 }}>{fare.label}</Typography>
                                                    </Grid>
                                                    <Grid item xs={true} style={{ paddingLeft: 15 }}>
                                                        <Typography
                                                            style={{
                                                                paddingLeft: 30,
                                                                fontSize: 17,
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            $ {fare.price}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

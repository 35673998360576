import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Skeleton,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BookingStore } from '../../../utils/stores/BookingStore';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { sum } from 'lodash';
import CalendarDateSel from './CalendarDateSel';
import SessionSelect from './SessionSelect';
import InfoIcon from '@mui/icons-material/Info';
import { gradients } from '../../../utils/theme';
import { makeStyles } from 'tss-react/mui';

const availabilityStyles = makeStyles()((theme) => ({
    paper: {
        zIndex: 8,
        padding: '16px 24px 16px',
        borderRadius: 10,
        width: '100%',
        [theme.breakpoints.only('sm')]: {
            padding: '8px 18px 24px'
        },
        [theme.breakpoints.only('xs')]: {
            zIndex: 5,
            padding: 0
        }
    },
    menu: {
        width: 190
    },
    menuButton: {
        width: '90%',
        height: 54,
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 14.5,
        paddingRight: 14.5,
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    },
    menuItem: {
        width: 280,
        paddingRight: 2
    },
    loadContainer: {
        width: '90%',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        height: 54,
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderStyle: 'solid',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgb(0 0 0 / 23%)'
    },
    title: {
        marginBottom: 15,
        width: '100%',
        textAlign: 'left',
        fontSize: 22,
        backgroundImage: gradients.main,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        [theme.breakpoints.down('md')]: {
            paddingTop: 15
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: '5%'
        }
    },
    buttonContainer: {
        justifyContent: 'center',
        alignContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'center',
            marginTop: 25
        }
    },
    button: {
        width: '80%',
        height: 54,
        backgroundImage: gradients.circular,
        [theme.breakpoints.down('md')]: {
            width: '90%'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '30px 0'
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    sectionItem: {
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignText: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            width: '90%'
        }
    },
    packageNote: {
        width: '100%',
        display: 'flex',
        marginTop: 20,
        [theme.breakpoints.only('sm')]: {
            padding: '0 2.5%'
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: 15,
            justifyContent: 'center',
            padding: 0
        }
    },
    packageNoteText: {
        color: theme.palette.text.soft,
        fontWeight: 400,
        fontSize: 17,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.3,
        paddingRight: '5%',
        [theme.breakpoints.only('xs')]: {
            paddingRight: 0
        }
    },
    calendarSize: {
        '& .MuiPopover-paper': {
            [theme.breakpoints.only('xs')]: {
                maxWidth: 'calc(100% - 10px)',
                overflowX: 'scroll',
                color: 'pink'
            }
        }
    },
    innerGridItem: {
        width: '100%',
        [theme.breakpoints.only('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));

const BookingOptions = () => {
    const isMounted = IsMountedWrapper();
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const classes = availabilityStyles().classes;
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const sumOfFares = sum(
        bookingController.booking.quantities && bookingController.booking.quantities.map((x) => x.value)
    );

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        setAnchorEl(null);
    };

    const addFare = (index: number) => {
        const participants = (bookingController.booking.participants || []).concat({
            fields: productController.product.participantInfoFields.map((x) => x)
        });
        bookingController.setBooking(
            (prev) => ({
                ...prev,
                quantities: prev.quantities.map((q, i) => (i === index ? { ...q, value: q.value + 1 } : q)),
                participants: participants
            }),
            isMounted
        );
    };

    const minusFare = (index: number) => {
        const participants =
            bookingController.booking.participants.length > 1
                ? bookingController.booking.participants.slice(0, bookingController.booking.participants.length - 1)
                : [];
        bookingController.setBooking(
            (prev) => ({
                ...prev,
                quantities: prev.quantities.map((q, i) => (i === index ? { ...q, value: q.value - 1 } : q)),
                participants: participants
            }),
            isMounted
        );
    };

    const optionsArray = productController.product?.priceOptions;
    return (
        <Grid item className={classes.sectionItem}>
            <Button
                onClick={openMenu}
                className={classes.menuButton}
                style={{
                    borderColor: anchorEl ? theme.palette.primary.main : sumOfFares > 0 ? 'green' : '#0000003b',
                    color: sumOfFares > 0 ? 'green' : '#757575'
                }}
                endIcon={anchorEl ? <ExpandLessIcon /> : <ArrowDropDownIcon />}
                disableFocusRipple
            >
                {sumOfFares > 0 ? `Qty ${sumOfFares} Selected` : 'View Options'}
            </Button>
            <Menu
                id="fare-type-select"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {optionsArray &&
                    optionsArray?.[0]?.label === bookingController.booking?.quantities?.[0]?.label &&
                    optionsArray.map((option, i) => {
                        const index =
                            bookingController.booking?.quantities &&
                            bookingController.booking?.quantities.findIndex((x) => x.label === option.label);
                        return (
                            <MenuItem key={`fare option ${i + 1}`} className={classes.menuItem}>
                                {index !== -1 && option.label && option.label.length > 0 ? (
                                    <>
                                        <ListItemText
                                            primary={
                                                option?.label.length > 17
                                                    ? option.label.substring(0, 18) + '...'
                                                    : option.label
                                            }
                                        />
                                        <IconButton
                                            aria-label="minus"
                                            disabled={bookingController.booking.quantities[index].value === 0}
                                            onClick={() => minusFare(index)}
                                            size="large"
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        <Typography style={{ padding: '0 5px' }}>
                                            {bookingController.booking.quantities[index].value}
                                        </Typography>
                                        <IconButton
                                            aria-label="plus"
                                            disabled={
                                                bookingController.booking.quantities[index].value ===
                                                productController.product.maxQuantity
                                            }
                                            onClick={() => addFare(index)}
                                            size="large"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <Skeleton width={100} height={40} />
                                )}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Grid>
    );
};

export default function CheckAvailability(props) {
    const classes = availabilityStyles().classes;
    const paperRef = useRef<HTMLHeadingElement>(null);
    const [bookingHeight, setBookingHeight] = useState(0);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const productController = useContext(ProductsStore);

    const calcHeight = () => {
        const height = paperRef.current && paperRef.current.offsetHeight;
        setBookingHeight(height);
    };

    useEffect(() => {
        calcHeight();
        window.addEventListener('resize', calcHeight);
        return () => {
            window.removeEventListener('resize', calcHeight);
        };
    }, []);

    useEffect(() => {
        calcHeight();
    }, [productController.product?.allowPackage]);

    return (
        <Grid
            container
            sx={{
                width: 1,
                justifyContent: 'center',
                flexDirection: 'row',
                py: 0,
                px: { xs: 2, lg: 0 }
            }}
        >
            <Paper
                elevation={isXS ? 0 : 2}
                className={classes.paper}
                ref={paperRef}
                sx={{
                    mt: { xs: 1, sm: 2, md: 3.5, lg: `-${bookingHeight * 0.5}px` },
                    mb: { xs: 1, sm: 2, md: 3.5 }
                }}
            >
                <Grid
                    container
                    sx={{
                        alignItems: { xs: 'center', sm: 'flex-end' },
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <Grid item xs={12} sm={6} md={3} className={classes.innerGridItem}>
                        <Typography variant="h5" color="primary" className={classes.title}>
                            Date
                        </Typography>
                        <CalendarDateSel />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.innerGridItem}>
                        <Typography variant="h5" color="primary" className={classes.title}>
                            Time
                        </Typography>
                        <SessionSelect />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.innerGridItem}>
                        <Typography variant="h5" color="primary" className={classes.title}>
                            Fare Types
                        </Typography>
                        <BookingOptions />
                    </Grid>
                    <Grid container className={classes.buttonContainer} item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={props.handleClickOpen}
                        >
                            Book Now
                        </Button>
                    </Grid>
                </Grid>
                {productController.product?.allowPackage && (
                    <Box component="div" className={classes.packageNote}>
                        <InfoIcon color="primary" fontSize="large" style={{ marginRight: 10 }} />
                        <Typography className={classes.packageNoteText}>
                            Start by choosing a departure time for the{' '}
                            {productController.product.packageProductNames[0]} portion of your package tour.
                        </Typography>
                    </Box>
                )}
            </Paper>
        </Grid>
    );
}

import { GSLEntities } from '../models/bookings';
import { emptyProduct, Product, ProductCategories } from '../models/products';

export const baseProducts: Product[] = [
    {
        ...emptyProduct,
        productCode: 'PSL9MT',
        entity: GSLEntities.MARINE,
        category: ProductCategories.PACKAGES,
        tagline: 'Jet Ski & Scenic Flight Package',
        titleSEO:
            'Jet ski and scenic flight package Airlie Beach. See Heart Reef, Whitehaven Beach, Hill Inlet, and Airlie Beach',
        usp: [
            'Fly & Jet Ski the Whitsundays',
            'Guaranteed Window Seats',
            'See Heart Reef & Hill Inlet',
            '60 minute guided jet ski tour',
            'No licence required'
        ],
        tags: [...emptyProduct.tags, 'popular', 'package', 'featureTile'],
        allowPackage: true,
        packageCode: 'PWGNPK',
        packageEntity: GSLEntities.GSL_WHITSUNDAYS,
        packageFare: {
            label: 'Adult',
            seatsUsed: 1
        },
        packageProductNames: ['Jet Ski', 'Scenic Flight'],
        sales: [
            {
                startDate: '2024-06-01T00:00:00+1000',
                endDate: '2024-06-28T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 439
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        category: ProductCategories.AIR,
        productCode: 'PWGNPK',
        name: 'Reef & Island Scenic Flight',
        tagline: 'Airlie Beach Scenic Flight Tour',
        titleSEO: 'Scenic flight of the Whitsundays and Great Barrier Reef from Airlie Beach',
        usp: [
            'Amazing views of the Reef & Whitsunday Islands',
            'See Heart Reef, Hill Inlet and Whitehaven from above',
            'Guaranteed Window Seats',
            'Regular Departure Times',
            'See all of the Whitsundays'
        ],
        tags: [...emptyProduct.tags, 'airlie', 'featureTile', 'popular'],
        saleTips: ['Popular']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.MARINE,
        category: ProductCategories.WATER,
        productCode: 'P14SFD',
        tagline: 'Jet Ski Airlie Beach',
        titleSEO: 'The best value Jet Ski tour in Airlie Beach',
        usp: [
            '60 Minute Jet Ski Tour',
            'Self drive your own ski',
            'Suitable for beginners',
            'No licence required',
            '2 Persons may share a ski'
        ],
        tags: [...emptyProduct.tags, 'popular', 'featureTile'],
        sales: [
            {
                startDate: '2024-06-01T00:00:00+1000',
                endDate: '2024-06-28T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Jet Ski',
                        price: 139
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        category: ProductCategories.WATER,
        name: 'Extended Jet Ski Tour',
        productCode: 'P7KCCG',
        tagline: 'Get more time on the water',
        titleSEO: '90 Minute Jet Ski tour in Airlie Beach',
        usp: [
            '90 minutes of Jet Ski goodness',
            'No licence required',
            'Full instruction included',
            'No extra charge for second passenger',
            'Adrenaline tour'
        ],
        tags: [...emptyProduct.tags, 'other', 'featureTile', 'popular']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        category: ProductCategories.WATER,
        cancellationPeriod: 2,
        name: 'Camira Sailing Adventure',
        productCode: 'PHJTMQ',
        extras: [],
        tagline: 'All-inclusive sailing tour',
        titleSEO: 'Sail on Camira from Airlie Beach to Whitehaven & Hill Inlet',
        excludeFares: ['Senior', 'Youth Student (15 - 17 years)', '1 Adult 1 Child', 'Family (2Ad 2Ch)'],
        usp: [
            'Sail the Whitsunday Islands',
            'Visit Whitehaven Beach & Hill Inlet',
            'Morning Tea, Lunch, & Afternoon Tea included',
            'Beer & Wine included',
            'Snorkel fringing reef in the Whitsundays'
        ],
        tags: [...emptyProduct.tags, 'other'],
        shortDescription: `Harness the wind and sail the azure waters of the Whitsundays onboard the impressive Camira, a speedy 85ft ocean-going catamaran.
            Hike to the lookout over spectacular Hill Inlet, leave some footprints on famous Whitehaven Beach, swim and enjoy a BBQ lunch onboard.
            An all-inclusive Whitsunday experience.`,
        longDescription: `<p>
                Departing daily from Port of Airlie, Camira will take you through the magical Whitsunday Islands, en route to Whitehaven Beach.
                At 85 foot long, Camira is a magnificant sailing catamaran, perfectly suited to gently cruising the beautiful blue waters of the Whitsundays.
            </p>
            <p>
                <b>Your adventure includes:</b>
            </p>
            <ul>
                <li>
                    Scenic sailing adventure through the Whitsunday Islands, to Whitehaven Beach & Hill Inlet
                </li>
                <li>
                    Morning Tea & Afternoon Tea
                </li>
                <li>
                    BBQ lunch, soft drink, and water
                </li>
                <li>
                    Beer and Wine from 11am
                </li>
                <li>
                    Beach activities
                </li>
                <li>
                    Guided walk to Hill Inlet lookout
                </li>
            </ul>`
    }
    /* {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        category: ProductCategories.AIR,
        name: 'Tandem Skydive',
        cancellationPeriod: 2,
        productCode: 'PW0RLN',
        extras: [],
        tagline: 'Skydive above the Whitsundays',
        titleSEO: 'Tandem Skydive from Airlie Beach',
        usp: [
            'Freefall from up to 15,000 Feet!',
            'The only beach landing skydive from Airlie',
            'Free transfers included',
            'Multiple times daily',
            'All instructions and gear provided'
        ],
        tags: [...emptyProduct.tags, 'other'],
        shortDescription: `Elevate your Whitsundays adventure to new heights with a thrilling skydiving experience. 
            Enjoy breathtaking views of the ocean as you jump from up to 15,000 feet, reaching speeds of up to 200 km/h in free fall. 
            This is the only beach skydive in the Whitsundays region.`,
        longDescription: `<p>
                Elevate your Whitsundays adventure to new heights with a thrilling skydiving experience. 
                Enjoy breathtaking views of the ocean as you jump from up to 15,000 feet, reaching speeds of up to 200 km/h in free fall. 
                This is the only beach skydive in the Whitsundays region.
            </p>
            <p>
                <b>Your adventure includes:</b>
            </p>
            <ul>
                <li>
                    Free return transfers from Airlie Beach
                </li>
                <li>
                    Tandem skydive from up to 15,000 Feet
                </li>
                <li>
                    Beach landing for no extra charge
                </li>
                <li>
                    All required equipment and instructions
                </li>
            </ul>
            <p>
                Located just 35 minutes south of Airlie Beach, our dropzone offers breathtaking views of the coastline, making it a serene and picturesque location for your skydiving adventure.
                You will enjoy scenic views of the Whitsundays as you climb to your drop height (about 20 mins), then an exhillerating freefall at speeds of up to 200km/h. 
                After deploying the parachute, it's 5-7 minutes flying down to your beach landing, where your instructor can show you how to turn and fly the parachute.                 
            </p>
            <ul>
                <li>
                    Please allow up to 6 hours for the whole experience, the booking time you select is your pick-up time in Airlie Beach.
                </li>
                <li>
                    Please note that a surcharge applies for guests over 95kg; this is due to rigging requirements, and is paid on the day of travel. 
                </li>
                <li>
                    Your booking time is the pick-up time in Airlie Beach.
                </li>
                <li>
                    Closed in shoes must be worn.
                </li>
                <li>
                    Minimum age is 16, and guardian consent is required if under 18. There is no maximum age!
                </li>
            </ul>`
    } */
];

export const sitemapArray = baseProducts.map((p) => ({
    productCode: p.productCode,
    entity: p.entity,
    name: p?.name || ''
}));

/*

Heli Products:

 {
        ...emptyProduct,
        entity: GSLEntities.SEAPLANES,
        category: ProductCategories.AIR,
        productCode: 'PXWDCC',
        tagline: 'Fly by Helictoper to Whitehaven Beach',
        titleSEO: 'Whitehaven Beach Helicopter Tour with Air Whitsunday',
        usp: [
            'Amazing views of the Whitsunday Islands',
            'See Hill Inlet and Whitehaven from above',
            'Arrive at Whitehaven Beach by Helicopter',
            'Guaranteed Private Tour',
            'Optional Food & Beverage for ultimate indulgence'
        ],
        tags: [...emptyProduct.tags, 'helicopter'],
        saleTips: ['Private Tour']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.SEAPLANES,
        category: ProductCategories.AIR,
        productCode: 'P4VB1H',
        tagline: 'See the Reef, and land on Whitehaven Beach',
        titleSEO: 'Whitehaven Beach & Great Barrier Reef Helicopter Tour',
        usp: [
            'Amazing views of the Reef & Whitsunday Islands',
            'See Heart Reef, Hill Inlet and Whitehaven from above',
            'Land on Whitehaven Beach by Helicopter',
            'Guaranteed Private Tour',
            'Optional Food & Beverage for ultimate indulgence'
        ],
        tags: [...emptyProduct.tags, 'helicopter'],
        saleTips: ['Private Tour']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.SEAPLANES,
        category: ProductCategories.AIR,
        productCode: 'PUSXGJ',
        tagline: 'Fly over Great Barrier Reef on your own Helictoper',
        titleSEO: 'Private tour of the Great Barrier Reef by Helictoper',
        usp: [
            'Amazing views of the Reef & Whitsunday Islands',
            'See Heart Reef, Hill Inlet and Whitehaven from above',
            'Guaranteed Window Seats',
            'Private Tour Booking',
            'See all of the Whitsundays'
        ],
        tags: [...emptyProduct.tags, 'helicopter'],
        saleTips: ['Private Tour']
    },


Non GSL products. WIP

   
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        productCode: 'PNRRXT',
        tagline: 'Sail the Whitsundays',
        titleSEO: 'Full day sailing tour in the Whitsundays, from Airlie Beach',
        usp: [
            'Sail on-board a Catamaran',
            'Lunch and snacks included',
            'Snorkel gear and stinger suit included',
            'Returns at sunset for the perfect days end',
            'Family Friendly product'
        ],
        tags: [...emptyProduct.tags, 'other']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        productCode: 'PPT0BS',
        tagline: 'Airlie Beach Segway Tour',
        titleSEO: 'Cruise the Airlie Beach foreshore on your own Segway',
        usp: [
            'Ride your own Segway',
            'Includes Dinner',
            'Stop for sunset pictures',
            'Briefing and safety equipment included',
            'Family Friendly product'
        ],
        tags: [...emptyProduct.tags, 'other']
    }

    Fly & Sunset Sail:

    {
        ...emptyProduct,
        entity: GSLEntities.AVIATION,
        category: ProductCategories.PACKAGES,
        productCode: 'PSJHC0',
        tagline: 'See the Whitsundays from above, followed by a Sunset Sail',
        titleSEO:
            'Scenic Flight and Sunset Sail Package. See the Whitsundays from above, with a relaxing evening sail.',
        usp: [
            'Amazing views of the Reef & Whitsunday Islands',
            'See Heart Reef, Hill Inlet and Whitehaven from above',
            'Guaranteed Window Seats',
            'Sunset Sail with a glass of bubbles and platter',
            'Half Day fly & cruise tour'
        ],
        tags: [...emptyProduct.tags, 'package'],
        sales: [
            {
                startDate: '2022-03-01T00:00:00+1000',
                endDate: '2022-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 269
                    }
                ]
            }
        ]
    },

*/

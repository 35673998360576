import { Container, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import moment from 'moment';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';
import { GSLEntities } from '../utils/models/bookings';
import { Product } from '../utils/models/products';
import { ProductsStore } from '../utils/stores/ProductsStore';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    containerPadding: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    lowerContainerPadding: {
        paddingTop: 20,
        paddingBottom: 50,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    paper: {
        padding: 12
    },
    gridItem: {
        marginBottom: 20
    },
    dateSquare: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgb(0 0 0 / 13%)'
    },
    textSections: {
        padding: '3px 0',
        display: 'flex',
        justifyContent: 'center'
    },
    available: {
        background: 'rgba(31, 201, 129, 0.1)',
        color: 'rgb(31, 201, 129)'
    },
    notAvailable: {
        background: 'rgb(255, 222, 222)',
        color: 'rgb(178, 64, 64)'
    }
}));

export default function AgentPage() {
    const classes = useStyles().classes;
    const productStore = useContext(ProductsStore);
    const gslProducts = productStore.products.filter((prod) => prod.entity !== GSLEntities.GSL_WHITSUNDAYS);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.only('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.remove();
        }
    }, []);

    const seatsAvailable = (product: Product, i: number) => {
        if (!product) {
            return 0;
        }
        const productAvail = productStore.availabilityRange.find((avail) => avail.product === product.productCode);
        const filterSessions =
            productAvail &&
            productAvail.sessions.filter((sesh) =>
                moment(sesh.startTime).utcOffset(10).isSame(moment().utcOffset(10).add(i, 'days'), 'day')
            );
        const availSeats = filterSessions
            ? filterSessions.reduce((acc, session) => acc + session.seatsRemaining, 0)
            : 0;
        return availSeats;
    };

    const DateGrid = (i: number, product?: Product) => {
        const seats = product ? seatsAvailable(product, i) : 0;
        return (
            <Grid item xs={true} key={`day${i}`}>
                <Grid
                    container
                    direction="column"
                    className={classes.dateSquare}
                    style={{ borderLeftWidth: i !== 0 && 0 }}
                >
                    <Typography className={classes.textSections} style={{ opacity: 0.7 }}>
                        {moment().utcOffset(10).add(i, 'days').format('ddd')}
                    </Typography>
                    <Typography variant="h6" style={{ margin: '6px 0', opacity: 0.95 }}>
                        {moment().utcOffset(10).add(i, 'days').format('Do')}
                    </Typography>
                    <Typography
                        variant={xsScreen ? 'body2' : 'body1'}
                        className={clsx(
                            classes.textSections,
                            !productStore.loadingInitialAvailability &&
                                (seats > 0 ? classes.available : classes.notAvailable)
                        )}
                    >
                        {productStore.loadingInitialAvailability ? (
                            <Skeleton style={{ width: '50%' }} />
                        ) : seats > 0 ? (
                            `${seats} rem.`
                        ) : (
                            'Sold Out'
                        )}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Helmet>
                <title>GSL Whitsundays - Airlie Beach | Tour Availability</title>
                <meta
                    name="description"
                    content="Tour availability for GSL Whitsundayss | Easy reference for Travel Agents, for availability on Airlie Beach tours."
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="Availability" tagline="See upcoming availability with GSL Whitsundays" />
            </Container>
            <Container maxWidth="md" className={classes.lowerContainerPadding}>
                <Grid container>
                    {productStore.isLoading
                        ? Array.from(Array(10).keys()).map((_, i) => (
                              <Grid key={`prod${i + 1}`} item xs={12} className={classes.gridItem}>
                                  <Typography variant="h5">
                                      <Skeleton style={{ width: 200 }} />
                                  </Typography>
                                  <Grid container style={{ marginTop: 8, borderRadius: 8 }}>
                                      {Array.from(Array(smScreen ? 5 : xsScreen ? 4 : 7).keys()).map((_, i) =>
                                          DateGrid(i)
                                      )}
                                  </Grid>
                              </Grid>
                          ))
                        : gslProducts.map((product: any, i: number) => (
                              <Grid key={`prod${i + 1}`} item xs={12} className={classes.gridItem}>
                                  <Typography variant="h5">{product.name ? product.name : <Skeleton />}</Typography>
                                  <Grid container style={{ marginTop: 8, borderRadius: 8 }}>
                                      {Array.from(Array(smScreen ? 5 : xsScreen ? 4 : 7).keys()).map((_, i) =>
                                          DateGrid(i, product)
                                      )}
                                  </Grid>
                              </Grid>
                          ))}
                </Grid>
            </Container>
        </>
    );
}

import { AutocompleteRenderInputParams, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export const defaultRenderInput = (params: AutocompleteRenderInputParams & TextFieldProps) => (
    <TextField
        {...params}
        variant={params.variant || 'outlined'}
        inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
        }}
    />
);

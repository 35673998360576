import { Step, StepConnector, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { BookingStore } from '../../utils/stores/BookingStore';
import { gradients } from '../../utils/theme';
import { withStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    stepperDotRoot: {
        color: '#767676',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    stepperDotColored: {
        color: theme.palette.primary.main
    },
    stepperDotCircle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    },
    completeStep: {
        width: 32,
        height: 32,
        [theme.breakpoints.down('md')]: {
            height: 28,
            width: 28
        },
        borderRadius: '50%',
        backgroundImage: gradients.circular,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    currentStep: {
        borderRadius: '50%',
        [theme.breakpoints.down('md')]: {
            height: 14,
            width: 14
        },
        height: 16,
        width: 16,
        backgroundImage: 'unset'
    },
    labelCommon: {
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            color: 'rgba(196, 196, 196, 1)',
            margin: '0 0 16px',
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            },
            fontWeight: 400
        }
    },
    colorLabel: {
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            backgroundImage: gradients.circular
        }
    },
    labelRoot: {
        '& .MuiStepLabel-root.MuiStepLabel-alternativeLabel': {
            flexDirection: 'column-reverse'
        }
    }
}));

const Connector = withStyles((theme) => ({
    alternativeLabel: {
        bottom: 16,
        top: 'unset',
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
        [theme.breakpoints.down('md')]: {
            bottom: 14,
            left: 'calc(-50% + 14px)',
            right: 'calc(50% + 14px)'
        }
    },
    active: {
        '& $line': {
            backgroundImage: gradients.circular
        }
    },
    completed: {
        '& $line': {
            backgroundImage: gradients.circular
        }
    },
    line: {
        height: 2,
        border: 0,
        borderRadius: 1,
        backgroundColor: 'rgb(196 196 196)'
    }
}))(StepConnector);

interface BookingStepperProps {
    numberOfSteps: number;
}

const LargeStepper = ({ numberOfSteps }: BookingStepperProps) => {
    const classes = useStyles().classes;
    const bookingController = useContext(BookingStore);
    const currentStep = bookingController.step;
    const theme = useTheme();
    const hideLabels = useMediaQuery(theme.breakpoints.down('md'));

    const stepLabels =
        numberOfSteps === 3
            ? [
                  bookingController.booking.isPackage ? 'Tour & Contact' : 'Lead Customer',
                  'Meeting Point',
                  'Review & Pay'
              ]
            : numberOfSteps === 4
              ? [
                    bookingController.booking.isPackage ? 'Tour & Contact' : 'Lead Customer',
                    'Details',
                    'Meeting Point',
                    'Review & Pay'
                ]
              : [
                    bookingController.booking.isPackage ? 'Tour & Contact' : 'Lead Customer',
                    'Details',
                    'Extras',
                    'Meeting Point',
                    'Review & Pay'
                ];

    const determineStep = (props: StepIconProps) => {
        if (props.completed) {
            return (
                <div className={classes.completeStep}>
                    <CheckIcon style={{ height: '70%', color: 'white' }} />
                </div>
            );
        } else if (props.active) {
            return (
                <div className={classes.completeStep}>
                    <div className={classes.currentStep} style={{ backgroundColor: 'white' }} />
                </div>
            );
        }
        return (
            <div
                className={classes.completeStep}
                style={{ backgroundColor: 'rgba(196, 196, 196, 1)', backgroundImage: 'unset' }}
            >
                <div className={classes.currentStep} style={{ backgroundColor: '#ffffff' }} />
            </div>
        );
    };

    return (
        <>
            <div style={{ background: 'transparent' }}>
                <Stepper
                    alternativeLabel
                    activeStep={currentStep}
                    connector={<Connector />}
                    style={{ background: 'transparent', padding: '20px 0 30px' }}
                >
                    {stepLabels.map((label: string, i: number) => (
                        <Step key={`step${i + 1}`} className={classes.labelRoot}>
                            <StepLabel
                                StepIconComponent={determineStep}
                                className={clsx(classes.labelCommon, {
                                    [classes.colorLabel]: i <= currentStep
                                })}
                            >
                                {!hideLabels && label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </>
    );
};

export default LargeStepper;

import { Box, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { widgetStyles } from './widgets/widgetStyles';
import HeartIcon from '@mui/icons-material/FavoriteBorder';

export default function KeySellingPointsWidget() {
    const classes = widgetStyles;
    const theme = useTheme();
    const productController = useContext(ProductsStore);

    return (
        <Paper variant="outlined" sx={{ ...classes.paper, ...classes.paperRight }}>
            <Grid container direction="column">
                <Typography variant="h5" sx={{ fontWeight: 500, pb: 1, pt: { xs: 2, sm: 0 } }}>
                    Reasons to Love It
                </Typography>
                <List>
                    {productController.product?.usp &&
                        productController.product.usp.map((item: any, i: any) => (
                            <ListItem
                                key={`usp${i}`}
                                sx={{
                                    color: (theme) => theme.palette.text.soft,
                                    pt: 1,
                                    pb: 1,
                                    pl: { xs: 0, md: 1 }
                                }}
                            >
                                <Box sx={{ mr: 1, display: 'flex' }}>
                                    <HeartIcon color="primary" sx={{ fontSize: { xs: 18, sm: 23 } }} />
                                </Box>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                        ))}
                </List>
            </Grid>
        </Paper>
    );
}

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from './utils/theme';

import TagManager from 'react-gtm-module';
import Header from './components/coreLayout/Header';
import Footer from './components/coreLayout/Footer';
import Pages from './utils/PageRouter';
import SnackbarContainer from './utils/SnackbarContainer';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export default function App() {
    const tagManagerArgs = { gtmId: 'GTM-N846N5L' };
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize('G-5HQ84WSL13');

    const setChatIndex = () => {
        const style = document.createElement('style');
        const css = document.createTextNode('.purechat-launcher-frame {z-index: 5 !important}');
        style.appendChild(css);
        document.head.appendChild(style);
    };

    useEffect(() => {
        setChatIndex();
    }, []);

    return (
        <div className="App">
            <SnackbarContainer />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <CssBaseline />
                        <Router>
                            <Grid container sx={{ minHeight: '100vh' }} flexDirection="column">
                                <Header />
                                <Pages />
                                <Grid xs={true} item />{' '}
                                {/* Place footer at the bottom of window, if content doesn't fill it*/}
                                <Footer />
                            </Grid>
                        </Router>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}

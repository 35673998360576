import { Container, Typography } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { ProductCategories } from '../utils/models/products';
import { categoryStyles } from './OnTheWater';

export default function Packages() {
    const classes = categoryStyles().classes;

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Whitsunday Package Tours | Airlie Beach Tour Packages with GSL Whitsundays</title>
                <meta
                    name="description"
                    content="Check out our Fly and Cruise packages, Jet Ski Packages, and unique tours departing from Airlie Beach in the Whitsundays"
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="Package Tours" tagline="Great experiences, great value" />
            </Container>
            <Container
                maxWidth="lg"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}
            >
                <Typography variant="h4" className={classes.title}>
                    Get the most of your time in the Whitsundays
                </Typography>
                <Typography className={classes.body}>
                    Package together unique Whitsunday experiences, and get the ulimate experience. Our range of
                    packages are designed for the best value, and the most ideal combination of products. The Fly &amp;
                    Cruise combinations are exceedingly popular, as well as our Jet Ski packages.
                </Typography>
            </Container>
            <ProductDisplayGrid category={ProductCategories.PACKAGES} />
            <div style={{ marginBottom: 40, width: '100%' }} />
        </>
    );
}

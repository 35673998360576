import { ceil, floor, toLower } from 'lodash';
import moment, { Duration } from 'moment';
import { routes } from '../PageRouter';
import { Product } from '../models/products';
import { baseProducts } from '../stores/productCodes';

export const convertToSearchableName = (productName) => {
    // eslint-disable-next-line
    return toLower(productName).replace(/[\s`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
};

export const productURL = (productName) => {
    const url = routes.products(convertToSearchableName(productName));
    return url;
};

export const minutesToDuration = (minutes: number | undefined): Duration => {
    if (minutes === undefined) {
        return moment.duration(0);
    } else {
        return moment.duration(minutes, 'minutes');
    }
};

export const getDurationStr = (minutesEntry) => {
    const duration = minutesToDuration(minutesEntry || 0);
    const days = duration.days() < 1 ? '' : duration.days() < 2 ? duration.days() + ' Day' : duration.days() + ' Days';
    const hours =
        duration.hours() < 1 ? '' : duration.hours() < 2 ? duration.hours() + ' Hour' : duration.hours() + ' Hours';
    const minutes =
        duration.days() >= 1
            ? ''
            : duration.minutes() < 1
              ? ''
              : duration.minutes() < 2
                ? duration.minutes() + ' Minute'
                : duration.minutes() + ' Minutes';

    return `${days} ${hours} ${minutes}`;
};

export const orderProducts = (productArray: Product[]) => {
    const index =
        productArray && baseProducts.map((prod) => productArray.findIndex((p) => p?.productCode === prod?.productCode));
    const newOrder = index.map((num) => productArray[num]).filter((p) => p?.productCode);
    return newOrder;
};

export const strToAmount = (str) => Math.round(Number(str) * 100);
export const amountUiFormat = (amount) => ceil(floor((amount || 0) / 100, 2), 2);
export const amountUiStr = (amount) => ceil(floor(amount || 0, 2), 2).toFixed(2);

export const displayCancellation = (days: number) => {
    const display = days === 1 ? '24 hours' : days === 2 ? '48 hours' : `${days} days`;
    return display;
};

export const isOnSale = (product: Product) => {
    const sale = (product.sales || []).find((sale) => moment().isBetween(moment(sale.startDate), moment(sale.endDate)));
    const salePrice = sale && sale.fareTypes[0].price;
    return salePrice;
};

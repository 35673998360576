import { GSLEntities, ParticipantInfoField } from './bookings';
import { RezdyPickUpLocation } from './rezdyObjects';

export interface Image {
    readonly url: string;
    readonly thumbnailURL?: string;
    readonly mediumURL?: string;
    readonly largeURL?: string;
    readonly alt: string;
}

export enum ApplyExtras {
    PER_PERSON = 'per_person',
    PER_BOOKING = 'per_booking'
}

export enum ProductCategories {
    WATER = 'water',
    AIR = 'air',
    LAND = 'land',
    PACKAGES = 'packages',
    OVERNIGHT = 'overnight'
}

export interface Extras {
    readonly id: string;
    readonly name?: string;
    readonly description?: string;
    readonly conditions?: string[];
    readonly appliesTo?: ApplyExtras;
    readonly image?: Image;
    readonly price?: number;
    readonly quantity?: number;
    readonly discount?: number;
}

export interface Product {
    readonly productCode: string;
    readonly category?: ProductCategories;
    readonly cancellationPeriod?: number;
    readonly entity: GSLEntities;
    readonly tags?: string[];
    readonly saleTips?: string[];
    readonly searchableName?: string;
    readonly excludeFares?: string[];
    readonly name: string;
    readonly duration?: number;
    readonly titleSEO: string;
    readonly tagline?: string;
    readonly shortDescription: string;
    readonly longDescription?: string;
    readonly photos: Image[];
    readonly usp: string[];
    readonly priceOptions?: PriceOption[];
    readonly participantInfoFields?: ParticipantInfoField[];
    readonly displayPrice: PriceOption;
    readonly sales?: Sale[] | null;
    readonly extras?: Extras[];
    readonly minimumNoticeMinutes?: number;
    readonly pickupID?: number;
    readonly pickupList?: RezdyPickUpLocation[];
    readonly maxQuantity?: number;
    readonly allowPackage?: boolean;
    readonly packageEntity?: GSLEntities;
    readonly packageCode?: string;
    readonly packageFare?: PriceOption;
    readonly packageProductNames?: string[];
    readonly map?: string;
}

export interface PriceOption {
    readonly label?: string;
    readonly price?: number;
    readonly value?: number;
    readonly seatsUsed?: number;
}

export interface Sale {
    readonly startDate: string;
    readonly endDate: string;
    readonly fareTypes: PriceOption[];
}

export const emptyProduct: Product = {
    productCode: '',
    entity: GSLEntities.GSL_WHITSUNDAYS,
    tags: ['gslWhitsundays'],
    maxQuantity: 10,
    name: '',
    titleSEO: '',
    shortDescription: '',
    longDescription: '',
    photos: [
        {
            url: '',
            alt: ''
        }
    ],
    usp: [''],
    priceOptions: [{}],
    displayPrice: {}
};

export const emptyPackageProduct: Product = {
    productCode: '',
    entity: GSLEntities.GSL_WHITSUNDAYS,
    cancellationPeriod: 1,
    tags: ['package'],
    maxQuantity: 10,
    name: '',
    titleSEO: '',
    shortDescription: '',
    longDescription: '',
    photos: [
        {
            url: '',
            alt: ''
        }
    ],
    usp: [''],
    displayPrice: {}
};

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import creditCardType from 'credit-card-type';
import { map } from 'lodash';
import { useContext, useState } from 'react';
import InputMask from 'react-input-mask';
import Mastercard from '../../../assets/icons/mastercard.svg';
import Visa from '../../../assets/icons/visa.svg';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { commonStyles } from './commonStyles';

interface ErrorObject {
    readonly error?: boolean;
    readonly message?: string;
}

interface Errors {
    readonly name?: ErrorObject;
    readonly number?: ErrorObject;
    readonly mm?: ErrorObject;
    readonly yy?: ErrorObject;
    readonly cvn?: ErrorObject;
}

const CreditCardDetails = () => {
    const classes = commonStyles().classes;
    const bookingController = useContext(BookingStore);
    const isMounted = IsMountedWrapper();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const [localCard, setLocalCard] = useState('');
    const [localCVN, setLocalCVN] = useState('');
    const [errors, setErrors] = useState<Errors>({});
    const [cardType, setCardType] = useState('');

    const checkErrors = () => {
        const errorObject: Errors = {
            name: !bookingController?.payment?.data?.cardDetails?.Name
                ? {
                      error: true,
                      message: 'Card Name is required'
                  }
                : { error: false },
            number:
                localCard.replace(/\s/g, '').length !== 16
                    ? {
                          error: true,
                          message: 'Check you have entered all digits'
                      }
                    : ['visa', 'mastercard'].indexOf(cardType) === -1
                      ? {
                            error: true,
                            message: 'Please use Mastercard or Visa'
                        }
                      : { error: false },
            mm:
                !bookingController?.payment?.data?.cardDetails?.ExpiryMonth ||
                bookingController.payment.data.cardDetails.ExpiryMonth.replace(/\s/g, '').length !== 2
                    ? {
                          error: true,
                          message: 'MM req'
                      }
                    : { error: false },
            yy:
                !bookingController?.payment?.data?.cardDetails?.ExpiryYear ||
                bookingController?.payment?.data?.cardDetails?.ExpiryYear.replace(/\s/g, '').length !== 4
                    ? {
                          error: true,
                          message: 'YYYY req'
                      }
                    : { error: false },
            cvn:
                localCVN.replace(/\s/g, '').length !== 3
                    ? {
                          error: true,
                          message: 'Check CVN'
                      }
                    : { error: false }
        };
        setErrors(errorObject);
        return errorObject;
    };

    const checkCardType = (cardNum) => {
        const result = creditCardType(cardNum)[0];
        const type = result && result.type;
        cardNum.replace(/\s/g, '').length > 0 ? setCardType(type) : setCardType('');
    };

    const SubmitButton = () => {
        return (
            <Grid container style={{ padding: xs ? '10px 5px' : 10 }}>
                <Grid item xs={12} sm={7} md={4} lg={6}>
                    <Button
                        className={classes.bookingButton}
                        variant="contained"
                        disabled={bookingController.isLoading}
                        onClick={() => {
                            !map(checkErrors(), (err) => err.error).includes(true) &&
                                bookingController.processBooking();
                        }}
                    >
                        {bookingController.isLoading ? (
                            <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />
                        ) : (
                            'Submit Booking'
                        )}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={7} md={4} lg={6} className={classes.encryptSect}>
                    <LockIcon style={{ color: 'rgb(0 168 0)', margin: '0 10px 0 15px' }} />
                    <Typography style={{ color: 'rgb(0 168 0)', padding: '14px 0', marginRight: 15 }}>
                        256 Bit Encryption
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {bookingController?.payment?.data?.paymentError ? (
                <Grid container>
                    <Typography variant="h6" style={{ color: 'red', paddingBottom: 10, width: '100%' }}>
                        Payment Error
                    </Typography>
                    <Typography style={{ fontSize: 16, color: theme.palette.text.soft, marginBottom: 20 }}>
                        Uh oh! We have a problem processing your payment. Please check that you have entered your card
                        details correctly. Please contact us if you&apos;re unable to complete your payment online, and
                        we will happily book your tour over the phone. Click below to clear the error.
                    </Typography>
                    <Button
                        className={classes.errorButton}
                        variant="outlined"
                        onClick={() => {
                            bookingController.payment.setData(
                                (prev) => ({
                                    ...prev,
                                    paymentError: false,
                                    amount: 1234
                                }),
                                isMounted
                            );
                        }}
                    >
                        OK, Got It
                    </Button>
                </Grid>
            ) : bookingController.booking?.bookingResponse?.error ? (
                <Grid container style={{ color: theme.palette.text.soft }}>
                    <Typography variant="h6" style={{ color: 'red', paddingBottom: 10, width: '100%' }}>
                        Booking Error
                    </Typography>
                    <Typography>
                        The error may be caused by missing information, or information that was rejected by our server.
                    </Typography>
                    <Typography component="div" style={{ margin: '10px 0' }}>
                        Don&apos;t worry, we&apos;ll get this sorted for you, but{' '}
                        <Box display="inline" style={{ color: 'red' }}>
                            please do not submit the booking again,
                        </Box>{' '}
                        as your payment may have been successful prior to the booking being rejected by our server.
                    </Typography>
                    <Typography>Please contact us, so that we can manually confirm your booking.</Typography>
                    <Typography style={{ margin: '10px 0' }}>
                        We sincerely apologise for the inconvenience, and our development team will investigate the
                        cause of this error.
                    </Typography>
                </Grid>
            ) : (
                <Grid container className={classes.cardContainer}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        item
                        xs={12}
                        md={9}
                        lg={12}
                        style={{
                            padding: xs ? '10px 5px' : '0 10px 10px',
                            marginBottom: 5,
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '100%'
                        }}
                    >
                        <img
                            src={Visa}
                            alt="visa_payment"
                            style={{ height: xs ? 35 : 40, marginRight: 10, opacity: cardType === 'mastercard' && 0.4 }}
                        />
                        <img
                            src={Mastercard}
                            alt="mastercard_payment"
                            style={{ height: xs ? 35 : 40, opacity: cardType === 'visa' && 0.4 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} lg={12} style={{ padding: xs ? '10px 5px' : 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            label="Name on Card"
                            variant="outlined"
                            color="primary"
                            type="text"
                            autoComplete="cc-name"
                            name="Name"
                            value={bookingController?.payment?.data?.cardDetails?.Name || ''}
                            error={errors?.name?.error}
                            helperText={errors?.name?.message}
                            onChange={(event) => bookingController.updateCardDetails(event, false)}
                            required={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PersonIcon color="action" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} lg={12} style={{ padding: xs ? '10px 5px' : 10 }}>
                        <InputMask
                            mask="9999 9999 9999 9999"
                            maskPlaceholder=" "
                            onChange={(event) => {
                                bookingController.updateCardDetails(event, true);
                                setLocalCard(event.target.value);
                                checkCardType(event.target.value);
                            }}
                            name="Number"
                            value={localCard}
                        >
                            <TextField
                                style={{ flex: 'auto', width: '100%' }}
                                label="Card Number"
                                variant="outlined"
                                color="primary"
                                type="text"
                                autoComplete="cc-number"
                                name="Number"
                                error={errors?.number?.error}
                                helperText={errors?.number?.message}
                                required={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {cardType === 'visa' ? (
                                                <img src={Visa} alt="visa icon" style={{ height: 35 }} />
                                            ) : cardType === 'mastercard' ? (
                                                <img src={Mastercard} alt="mastercard icon" style={{ height: 35 }} />
                                            ) : (
                                                <CreditCardIcon color="action" />
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </InputMask>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={3} lg={4} style={{ padding: xs ? '10px 5px' : 10 }}>
                            <InputMask
                                mask={[/[0-1]/, /[0-9]/]}
                                maskPlaceholder=" "
                                onChange={(event) => bookingController.updateCardDetails(event, false)}
                                name="ExpiryMonth"
                                value={bookingController.payment?.data?.cardDetails?.ExpiryMonth || ''}
                            >
                                <TextField
                                    label="Exp MM"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    autoComplete="cc-exp-month"
                                    color="primary"
                                    type="text"
                                    name="ExpiryMonth"
                                    error={errors?.mm?.error}
                                    helperText={errors?.mm?.message}
                                    required={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <TodayIcon color="action" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={4} style={{ padding: xs ? '10px 5px' : 10 }}>
                            <InputMask
                                mask={[/[2]/, /[0]/, /[2-3]/, /[0-9]/]}
                                maskPlaceholder=" "
                                onChange={(event) => bookingController.updateCardDetails(event, false)}
                                name="ExpiryYear"
                                value={bookingController.payment?.data?.cardDetails?.ExpiryYear || ''}
                            >
                                <TextField
                                    label="Exp YYYY"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    color="primary"
                                    autoComplete="cc-exp-year"
                                    type="text"
                                    name="ExpiryYear"
                                    error={errors?.yy?.error}
                                    helperText={errors?.yy?.message}
                                    required={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalendarTodayIcon color="action" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={4} style={{ padding: xs ? '10px 5px' : 10 }}>
                            <InputMask
                                mask="999"
                                maskPlaceholder=" "
                                onChange={(event) => {
                                    bookingController.updateCardDetails(event, true);
                                    setLocalCVN(event.target.value);
                                }}
                                name="CVN"
                                value={localCVN}
                            >
                                <TextField
                                    label="CVN"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    color="primary"
                                    type="text"
                                    autoComplete="cc-csc"
                                    name="CVN"
                                    error={errors?.cvn?.error}
                                    helperText={errors?.cvn?.message}
                                    required={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockIcon color="action" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </InputMask>
                        </Grid>
                    </Grid>
                    <SubmitButton />
                </Grid>
            )}
        </>
    );
};

export default CreditCardDetails;

import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material/';
import { map } from 'lodash';
import React, { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';

export default function TermsAndConditions() {
    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.remove();
        }
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const termsList = [
        'GSL Whitsundays operate as an agent for tour operators in the Whitsundays, and is not the operating entity for any products sold on this site',
        'Prices listed on this site are per person, or as otherwise described (such as hire items)',
        'Operators often require individual infortmation for guests travelling on tours, such as names, weights (for flight products), dietary requirements, fitness level, or other details as determined by the operator. GSL Whitsundays will collect this information on their behalf.',
        'Once bookings are confirmed, they are subject to the terms and conditions of the operator. You should confirm these details by checking the website of the operator you are travelling with.',
        '100% cancellation fee applies for no shows, cancellations, or booking changes within the cancellation periods of your tour.',
        'Refunds issued may exclude the credit card processing fee.',
        'Prices advertised include GST, and usually include the applicable Marine Park fees, and National Park fees. Some operators may charge these amounts separately.',
        'Operators may cancel tours affected by adverse weather conditions, mechanical breakdown, or other such scenarios that make it unsafe or otherwise not possible to operate. GSL Whitsundays will endeavour to reschedule tours, accommodate guests on other tours, or provide alternative options. In the event that guests are not able to travel, the fare will be refunded, subject to the terms of the operator.',
        'Should any one part of a package tour not operate - you will be charged the current retail price of the tour component(s) completed.',
        'GSL Whitsundays accept no responsibility for missing tour/flight connections as a result of operational delays with products booked by this agency.',
        'Tours in the Whitsundays are covered by carriers liability insurance, and operators hold liability insurance for prescribed activitiies; those policies may not extend to claims where guests are found to be negligent, such as not following directions of crew.',
        'GSL Whitsundays highly recommend that all guest have their own travel insurance, to cover losses such as lost/damaged personal items on tour, injuries caused by negligence, tour delays, illness, and other such scenarios which may cause financial or personal loss outside of prescribed liabilites under the policies of the operator(s) you travel with.',
        'American Express and Diners Club cards are not accepted.'
    ];

    return (
        <div>
            <Helmet>
                <title>
                    GSL Whitsundays | Terms &amp; Conditions | Great Barrier Reef &amp; Whitehaven Beach Tours - Airlie
                    Beach
                </title>
                <meta
                    name="description"
                    content="Terms and conditions for travelling tours booked by GSL Whitsundays. Tours depart from Airlie Beach, and guests are encourgaged to check our terms &amp; conditions before travel"
                />
            </Helmet>
            <Container
                maxWidth="lg"
                sx={{
                    px: { xs: 2, sm: 4 }
                }}
            >
                <PageTitle
                    title="Terms &amp; Conditions"
                    tagline="A few things to know when booking with GSL Whitsundays"
                />
            </Container>
            <Container
                maxWidth="md"
                sx={{
                    px: { xs: 2, sm: 4 }
                }}
            >
                <Grid container sx={{ pb: 3, pt: { xs: 1, sm: 2 } }}>
                    <Grid item xs={12}>
                        <Box>
                            <List>
                                {map(termsList, (term, i) => (
                                    <ListItem key={`term${i + 1}`} sx={{ px: 0, py: 0.75, opacity: 0.8 }}>
                                        <ListItemText>
                                            <Box style={{ display: 'flex' }}>
                                                <Typography style={{ minWidth: 25, textAlign: 'right' }}>
                                                    {i + 1}.
                                                </Typography>
                                                <Typography style={{ marginLeft: 6 }}>{term}</Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

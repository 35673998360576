import React from 'react';
import { WrappedUseState } from '../functions/hooks';

export type WrappedSetter<T> = (next: T | ((prev: T) => T), isMounted: React.MutableRefObject<boolean>) => void;

export interface BaseStoreValues<T> {
    readonly isLoading: boolean;
    readonly setIsLoading: WrappedSetter<boolean>;
    readonly mainObject: T;
    readonly setMainObject: WrappedSetter<T>;
    readonly mainEntities: T[];
    readonly setMainEntities: WrappedSetter<T[]>;
}

export function BaseStore<T> (defaultValue: T): BaseStoreValues<T> {
    const isLoading = WrappedUseState<boolean>(true);
    const mainObject = WrappedUseState<T>(defaultValue);
    const mainEntities = WrappedUseState<T[]>([]);

    return {
        isLoading: isLoading.data,
        setIsLoading: isLoading.setData,
        mainObject: mainObject.data,
        setMainObject: mainObject.setData,
        mainEntities: mainEntities.data,
        setMainEntities: mainEntities.setData
    };
}

import { Container, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { ProductCategories } from '../utils/models/products';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const categoryStyles = makeStyles()((theme) => ({
    containerPadding: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    title: {
        fontSize: 38,
        color: theme.palette.primary.main,
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        paddingBottom: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
            marginTop: 40
        },
        textAlign: 'center'
    },
    body: {
        fontSize: 18,
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 15,
        maxWidth: '75%',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            maxWidth: '90%'
        },
        textAlign: 'center'
    }
}));

export default function OnTheWater() {
    const { classes } = categoryStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Whitsunday Tours on the Water | Cruise | Jet Ski | Sail</title>
                <meta
                    name="description"
                    content="Explore the Whitsundays, with a range of tours departing from Airlie Beach, including Whitehaven Beach, snorkelling, and the Great Barrier Reef"
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="On the Water" tagline="Get on the water, and explore the Whitsundays" />
            </Container>
            <Container
                maxWidth="lg"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}
            >
                <Typography variant="h4" className={classes.title}>
                    The Famous Waters of the Whitsundays
                </Typography>
                <Typography className={classes.body}>
                    There&apos;s something iconic about the azure blues of the Whitsunday Waters. The beautiful colours
                    of the water, surrounded by the green and rugged surrounds of the Whitsunday Islands, make this one
                    of the most amazing landscapes in Australia. When you&apos;re in Airlie Beach, it&apos;s an absolute
                    must to get out on the water, and experience it for yourself.
                </Typography>
            </Container>
            <ProductDisplayGrid category={ProductCategories.WATER} />
            <div style={{ marginBottom: 40, width: '100%' }} />
        </>
    );
}

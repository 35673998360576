import React, { useContext, useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { isEmpty } from 'lodash';
import { Grid, IconButton, Skeleton } from '@mui/material';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { makeStyles } from 'tss-react/mui';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const galleryStyles = makeStyles()((theme) => ({
    icon: {
        top: '50%',
        position: 'absolute',
        transform: 'translate(0, -50%)',
        color: theme.palette.text.primary,
        background: theme.palette.common.white,
        zIndex: 7,
        marginLeft: 28,
        opacity: 0.75,
        [theme.breakpoints.only('xs')]: {
            marginLeft: 14,
            width: 20,
            color: theme.palette.common.white,
            background: 'unset',
            '& .MuiSvgIcon-root': {
                fontSize: 40
            }
        }
    },
    rightIcon: {
        right: 28,
        [theme.breakpoints.only('xs')]: {
            right: 14
        }
    },
    imageArea: {
        width: '100%',
        '& .image-gallery-slides img': {
            objectFit: 'cover',
            [theme.breakpoints.down('md')]: {
                borderRadius: 0
            },
            height: 'inherit'
        },
        '& .image-gallery-content': {
            maxWidth: 1280,
            margin: 'auto'
        },
        '& .image-gallery.fullscreen-modal img': {
            height: 'unset'
        },
        '& .image-gallery.fullscreen-modal': {
            zIndex: 9
        },
        [theme.breakpoints.only('xs')]: {
            borderRadius: 0
        },
        borderRadius: 10,
        overflow: 'hidden'
    },
    image: {
        maxHeight: 'unset'
    }
}));

export default function ProductImageGallery() {
    const { classes, cx } = galleryStyles();
    const productController = useContext(ProductsStore);
    const galleryElement = useRef<HTMLHeadingElement>(null);
    const [startIndex, setStartIndex] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const images = productController.product?.photos;

    const calcImageHeight = () => {
        const imageWidth = galleryElement.current && galleryElement.current.clientWidth;
        return imageWidth * 0.59;
    };

    const calcGalleryHeight = () => {
        const style = document.createElement('style');
        const css = document.createTextNode(
            `.image-gallery-content .image-gallery-slide .image-gallery-image {height: ${calcImageHeight()}px`
        );
        style.appendChild(css);
        document.head.appendChild(style);
    };

    useEffect(() => {
        calcGalleryHeight();
        window.addEventListener('resize', calcGalleryHeight);
        return () => {
            window.removeEventListener('resize', calcGalleryHeight);
        };
    }, [images]);

    return (
        <>
            {!isEmpty(images) ? (
                <div ref={galleryElement} style={{ width: '100%' }}>
                    <Grid
                        item
                        xs={12}
                        className={classes.imageArea}
                        sx={{ width: 1, zIndex: isFullScreen ? 9 : 7, px: { xs: 0, sm: 2 } }}
                    >
                        <ImageGallery
                            onScreenChange={setIsFullScreen}
                            additionalClass={classes.imageArea}
                            startIndex={startIndex}
                            showPlayButton={false}
                            slideDuration={200}
                            swipeThreshold={25}
                            lazyLoad
                            showThumbnails={false}
                            useBrowserFullscreen={false}
                            items={(images || []).map((image) => ({
                                original: image.url
                            }))}
                            renderLeftNav={(onClick, disabled) => (
                                <IconButton
                                    disabled={disabled}
                                    onClick={(event) => {
                                        onClick(event);
                                        setStartIndex((prev) => (prev === 0 ? (images || []).length - 1 : prev - 1));
                                    }}
                                    className={classes.icon}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                            )}
                            renderRightNav={(onClick, disabled) => (
                                <IconButton
                                    disabled={disabled}
                                    onClick={(event) => {
                                        onClick(event);
                                        setStartIndex((prev) => (prev + 1) % (images || []).length);
                                    }}
                                    className={cx(classes.icon, classes.rightIcon)}
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                            )}
                        />
                    </Grid>
                </div>
            ) : (
                <div style={{ width: '100%' }} ref={galleryElement}>
                    <Skeleton width="100%" height={calcImageHeight()} />
                </div>
            )}
        </>
    );
}

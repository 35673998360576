import axios from 'axios';
import { isEqual, unionBy } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { orderProducts } from '../functions/common';
import { State, WrappedUseState, WrappedUseStateReturn } from '../functions/hooks';
import IsMountedWrapper from '../functions/isMountedWrapper';
import { convertRezdyProduct } from '../functions/rezdy';
import { GSLEntities } from '../models/bookings';
import { Product, emptyPackageProduct, emptyProduct } from '../models/products';
import { RezdyPriceOption } from '../models/rezdyObjects';
import { BaseStore, BaseStoreValues, WrappedSetter } from './BaseStore';
import { DefaultProviderProps, createStore, createStoreContext } from './common';
import { baseProducts } from './productCodes';

const apiURL =
    process.env.NODE_ENV === 'production' ? 'https://gslwhitsundays.com.au/v2/' : 'http://localhost:5000/v2/';

export interface SessionValue {
    date: string;
    startTime: string;
    seatsRemaining: number;
    id: string;
    priceOptions?: RezdyPriceOption[];
}

export interface AvailabilityValue {
    product?: string;
    lastFetch?: string;
    departureDate?: string;
    packageDepartureDate?: string;
    sessions?: SessionValue[];
    packageSessions?: SessionValue[];
}

export interface ProductsStoreValue extends BaseStoreValues<Product> {
    readonly products: Product[];
    readonly product: Product;
    readonly packageProducts: Product[];
    readonly packageProduct: WrappedUseStateReturn<Product>;
    readonly setProduct: WrappedSetter<Product>;
    readonly setProducts: WrappedSetter<Product[]>;
    readonly availabilityRange: AvailabilityValue[];
    readonly loadingInitialAvailability: boolean;
    readonly getAvailability: () => void;
    readonly availability: AvailabilityValue;
    readonly setAvailability: WrappedSetter<AvailabilityValue>;
    readonly clearController: () => void;
    readonly loadProductInfo: (prods: Product[]) => void;
}

export const ProductsStore = createStoreContext<ProductsStoreValue>();

export const ProductsStoreProvider = ({ children }: DefaultProviderProps) => {
    const base = BaseStore<Product>(emptyProduct);
    const isMounted = IsMountedWrapper();
    const availability = State<AvailabilityValue>({ departureDate: moment().toISOString() }, isMounted);
    const availabilityRange = State<AvailabilityValue[]>([], isMounted);
    const loadingInitialAvailability = State<boolean>(true, isMounted);
    const packageProducts = WrappedUseState<Product[]>([emptyPackageProduct]);
    const packageProduct = WrappedUseState<Product>(emptyPackageProduct);

    const clearController = () => {
        base.setMainObject(emptyProduct, isMounted);
        packageProduct.setData(emptyPackageProduct, isMounted);
        availability.set({ departureDate: moment().toISOString() });
    };

    const callProduct = async (product: Product) => {
        try {
            const result = await axios(
                `${apiURL}rezdy/products?productCode=${product.productCode}&entity=${product.entity}`
            );
            const convertedProduct =
                result.data.requestStatus?.success && convertRezdyProduct(product, result.data.product);
            return convertedProduct;
        } catch (e) {
            console.log(e);
        }
    };

    const callPackageProduct = async (product: Product) => {
        try {
            const result = await axios(
                `${apiURL}rezdy/products?productCode=${product.packageCode}&entity=${product.packageEntity}`
            );
            const convertedProduct =
                result.data.requestStatus?.success &&
                convertRezdyProduct(
                    {
                        ...emptyPackageProduct,
                        entity: product.packageEntity
                    },
                    result.data.product
                );
            return convertedProduct;
        } catch (e) {
            console.log(e);
        }
    };

    const loadProductInfo = async (prods: Product[]) => {
        base.setIsLoading(true, isMounted);
        const completedProds = prods.map((product) => callProduct(product));
        const packageProds = prods.map((product) => product.allowPackage && callPackageProduct(product));
        Promise.allSettled(completedProds)
            .then((results) => {
                const toSort = results
                    .map((res) => res.status === 'fulfilled' && (res.value ? res.value : null))
                    .filter((r) => r?.productCode);
                const sortedArray = orderProducts(toSort);
                base.setMainEntities(sortedArray, isMounted);
            })
            .finally(() => {
                base.mainEntities && base.setIsLoading(false, isMounted);
            });
        Promise.allSettled(packageProds).then((results) => {
            const packageArray = results
                .map((res) => {
                    if (res.status === 'fulfilled' && res.value !== undefined) {
                        return res.value;
                    } else return null;
                })
                .filter((x) => x !== null);
            packageProducts.setData(packageArray, isMounted);
        });
    };

    const getAvailability = async () => {
        const existingAvailability = availabilityRange.data.find(
            (avail) => avail.product === base.mainObject.productCode
        );
        const validAvailability =
            existingAvailability && moment(existingAvailability.lastFetch).isAfter(moment().subtract(5, 'minutes'));
        const validExistingSessions =
            validAvailability &&
            existingAvailability.sessions.filter((sesh) =>
                moment(sesh.startTime)
                    .utcOffset(10)
                    .isSame(availability?.data.departureDate, 'day')
            );
        validExistingSessions &&
            availability.set((prev) => ({
                ...prev,
                sessions: prev.sessions ? unionBy(prev.sessions, validExistingSessions, 'id') : validExistingSessions
            }));
        const newDates = availability.data.sessions
            ? !availability.data.sessions.find((s) =>
                  moment(s.startTime).isSame(availability?.data.departureDate, 'day')
              )
            : true;
        try {
            base.setIsLoading(true, isMounted);
            const date = moment(availability.data.departureDate).toISOString();
            const response =
                base.mainObject?.productCode &&
                newDates &&
                (await axios(
                    `${apiURL}rezdy/availability?date=${date}&product=${base.mainObject.productCode}&entity=${base.mainObject.entity}`
                ));
            response &&
                availability.set((prev) => ({
                    ...prev,
                    sessions: prev.sessions
                        ? unionBy(prev.sessions, response.data as SessionValue[], 'id')
                        : response.data
                }));
            base.mainObject?.allowPackage && getPackageAvailability();
        } catch (e) {
            console.error(e);
        } finally {
            base.setIsLoading(false, isMounted);
        }
    };

    const getDateRangeAvailability = async (startTime: string, endTime: string, product: Product) => {
        const res = await axios(
            `${apiURL}rezdy/availabilityrange?startTime=${startTime}&endTime=${endTime}&product=${product.productCode}&entity=${product.entity}`
        );
        return res.data;
    };

    const getInitialAvailability = async () => {
        loadingInitialAvailability.set(true);
        const startDate = moment().toISOString();
        const endDate = moment().add(7, 'days').endOf('day').toISOString();
        Promise.allSettled(baseProducts.map((prod) => getDateRangeAvailability(startDate, endDate, prod)))
            .then((results) => {
                const resolved = results.map((res) => res.status === 'fulfilled' && res.value);
                availabilityRange.set(resolved);
            })
            .finally(() => {
                base.mainEntities && loadingInitialAvailability.set(false);
            });
    };

    const getPackageAvailability = async () => {
        const newDates = availability.data.packageSessions
            ? !availability.data.packageSessions.find((s) =>
                  moment(s.startTime).isSame(availability?.data.packageDepartureDate, 'day')
              )
            : true;
        try {
            const date = moment(
                availability.data.packageDepartureDate
                    ? availability.data.packageDepartureDate
                    : availability.data.departureDate
            ).toISOString();
            const response =
                base.mainObject.packageCode &&
                newDates &&
                (await axios(
                    `${apiURL}rezdy/availability?date=${date}&product=${base.mainObject.packageCode}&entity=${base.mainObject.packageEntity}`
                ));
            response &&
                availability.set((prev) => ({
                    ...prev,
                    packageSessions: prev.packageSessions
                        ? unionBy(prev.packageSessions, response.data as SessionValue[], 'id')
                        : response.data
                }));
        } catch (e) {
            console.error(e);
        } finally {
            base.setIsLoading(false, isMounted);
        }
    };

    const getPickups = async (pickupId: number, entity: GSLEntities) => {
        try {
            const response = await axios(`${apiURL}rezdy/pickups?pickupId=${pickupId}&entity=${entity}`);
            if (response) {
                !isEqual(base.mainObject?.pickupList, response.data) &&
                    base.setMainObject(
                        (prev) => ({
                            ...prev,
                            pickupList: response.data
                        }),
                        isMounted
                    );
                base.setMainEntities((prev) => {
                    const tempArray = prev.map((p) => {
                        if (p.pickupID === pickupId) {
                            return {
                                ...p,
                                pickupList: response.data
                            };
                        } else {
                            return {
                                ...p
                            };
                        }
                    });
                    return tempArray;
                }, isMounted);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getPackagePickups = async (pickupId: number, entity: GSLEntities) => {
        try {
            const response = await axios(`${apiURL}rezdy/pickups?pickupId=${pickupId}&entity=${entity}`);
            response &&
                packageProduct.setData(
                    (prev) => ({
                        ...prev,
                        pickupList: response.data
                    }),
                    isMounted
                );
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        base.mainObject.allowPackage &&
            packageProduct.data?.pickupID &&
            getPackagePickups(packageProduct.data.pickupID, packageProduct.data.entity);
    }, [packageProduct.data?.pickupID]);

    useEffect(() => {
        base.mainObject?.pickupID && getPickups(base.mainObject.pickupID, base.mainObject.entity);
    }, [base.mainObject?.productCode]);

    useEffect(() => {
        if (baseProducts) {
            loadProductInfo(baseProducts);
            getInitialAvailability();
        }
    }, []);

    useEffect(() => {
        base.mainObject.allowPackage && availability.data.packageDepartureDate && getPackageAvailability();
    }, [availability.data.packageDepartureDate]);

    return createStore(ProductsStore, children, {
        ...base,
        product: base.mainObject,
        setProduct: base.setMainObject,
        products: base.mainEntities,
        setProducts: base.setMainEntities,
        packageProducts: packageProducts.data,
        packageProduct: packageProduct,
        availability: availability.data,
        availabilityRange: availabilityRange.data,
        loadingInitialAvailability: loadingInitialAvailability.data,
        setAvailability: availability.set,
        getAvailability,
        clearController,
        loadProductInfo
    });
};

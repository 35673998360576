import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormGroup,
    Grid,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Tick from '@mui/icons-material/CheckCircleOutline';
import Cross from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import IsMountedWrapper from '../../utils/functions/isMountedWrapper';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { PickUpListProps } from '../bookings/formSections/SelectPickUp';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const styles = makeStyles()((theme) => ({
    pickUpCheckBox: {
        padding: '7px 0 7px 12px',
        borderRadius: 10,
        '& .MuiTypography-body1': {
            fontSize: 19,
            fontWeight: 400,
            [theme.breakpoints.down('md')]: {
                fontSize: 17
            }
        },
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
        marginRight: 10,
        marginBottom: 5
    }
}));

function SelectLocation() {
    const isMounted = IsMountedWrapper();
    const { classes } = styles();
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const product = productController.product;
    const booking = bookingController.booking;
    const needPickUp = bookingController.needPickUp.data;
    const locationSelected =
        booking?.tour?.pickUpLocation && booking?.tour?.pickUpLocation !== product?.pickupList?.[0].locationName;
    const locationSelectedRef = useRef<boolean>();
    locationSelectedRef.current = locationSelected;

    const checkPickUp = () => {
        if (!locationSelectedRef.current) {
            product.pickupList[0].locationName &&
                bookingController.changePickUp(null, product.pickupList[0].locationName);
        }
    };

    useEffect(() => {
        checkPickUp();
        const pickUpStatus =
            product.pickupList
                .slice(1, product.pickupList.length)
                .find((p) => p.locationName === booking?.tour?.pickUpLocation) !== undefined;
        bookingController.needPickUp.setData(pickUpStatus, isMounted);
        return () => {
            checkPickUp();
        };
    }, [booking?.operatorRef, product?.pickupList]);

    useEffect(() => {
        locationSelectedRef.current = locationSelected;
    }, [booking?.operatorRef]);

    const PickUpList = ({ product }: PickUpListProps) => {
        return (
            <>
                {product.pickupList.slice(1, product.pickupList.length).map((location, i) => (
                    <option key={`$loc${i}`} value={location.locationName}>
                        {location.locationName}
                    </option>
                ))}
            </>
        );
    };

    return (
        <>
            <Grid container style={{ marginBottom: 15 }}>
                <Grid item xs="auto">
                    <FormGroup row className={classes.pickUpCheckBox}>
                        <Typography style={{ padding: '0 5px 0 0' }}>Need a Ride?</Typography>
                        <Switch
                            color="primary"
                            checked={needPickUp}
                            onChange={bookingController.togglePickUp}
                            name="selectPickUp"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={true} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Check here to select a pick-up</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    label="Select Location"
                    value={booking.tour.pickUpLocation || ''}
                    onChange={bookingController.changePickUp}
                    disabled={!needPickUp}
                    name="pickupLocation"
                    SelectProps={{
                        native: true
                    }}
                    variant="outlined"
                >
                    <option value="" />
                    <PickUpList product={product} />
                </TextField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 15 }}>
                <Typography>Current Meeting Point</Typography>
                <Typography style={{ fontWeight: 300 }}>{booking.tour.pickUpLocation || 'TBA'}</Typography>
                {product.pickupList[0].locationName !== booking.tour.pickUpLocation ? (
                    <Typography style={{ fontWeight: 300 }}>
                        At this Time: {bookingController.calcDisplayTime(product, false) || 'TBA'}
                    </Typography>
                ) : (
                    <Typography style={{ fontWeight: 300, marginTop: 5 }}>
                        Check In by {bookingController.calcDisplayTime(product, false) || 'TBA'}
                    </Typography>
                )}
            </Grid>
        </>
    );
}

export default function ChangePickUp() {
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const pickUpExists =
        productController.product?.pickupList &&
        productController.product.pickupList.slice(1, productController.product.pickupList.length).length > 0;

    const checkPickUpValid = () => {
        const isValid =
            !isEmpty(bookingController.booking?.tour?.pickUpLocation) &&
            (productController.product?.pickupList || []).find(
                (pu) => pu.locationName === bookingController.booking.tour.pickUpLocation
            ) !== undefined;
        return isValid;
    };

    const [pickUpValid, setPickUpValid] = useState(checkPickUpValid());

    useEffect(() => {
        return () => {
            pickUpExists && !bookingController.needPickUp && bookingController.resetPickUp();
        };
    }, [bookingController.booking?.operatorRef, productController.product?.pickupList]);

    useEffect(() => {
        setPickUpValid(checkPickUpValid());
    }, [bookingController.booking?.tour, productController.product?.pickupList]);

    if (pickUpExists) {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="transport-content"
                    id="transport-header"
                >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {pickUpValid ? (
                            <Tick style={{ marginRight: 5, padding: 1, color: '#24bb00' }} />
                        ) : (
                            <Cross color="error" style={{ marginRight: 5, padding: 1 }} />
                        )}
                        <Typography>Transport & Check-In</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography style={{ paddingBottom: 15, fontWeight: 300 }}>
                                Please select your transport option below. You can come back here and change it later,
                                if you need to.
                            </Typography>
                        </Grid>
                        <SelectLocation />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    } else return null;
}

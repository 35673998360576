import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { gradients } from '../../utils/theme';

interface TitleProps {
    title: string;
    tagline: string;
}

export default function PageTitle({ title, tagline }: TitleProps) {
    return (
        <Box
            sx={{
                px: { xs: 0, sm: 2 },
                pt: { xs: 1, sm: '3rem' },
                pb: { xs: 1, sm: '2rem' },
                width: 1,
                textAlign: { xs: 'center', sm: 'left' }
            }}
        >
            <Grid container direction="column">
                <Typography
                    variant="h1"
                    sx={{
                        backgroundImage: gradients.main,
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        fontSize: { xs: 36, sm: 48, md: 55 },
                        fontWeight: 500,
                        paddingBottom: 1
                    }}
                >
                    {title || ''}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: (theme) => theme.palette.primary.dark,
                        fontSize: { xs: 18, sm: 20, md: 24 },
                        opacity: 0.8,
                        fontWeight: 400
                    }}
                >
                    {tagline || ''}
                </Typography>
            </Grid>
        </Box>
    );
}

import React, { useState } from 'react';
import { WrappedSetter } from '../stores/BaseStore';

export interface WrappedUseStateReturn<T> {
    readonly data: T;
    readonly setData: WrappedSetter<T>;
}

export function WrappedUseState<T> (defaultValue: T): WrappedUseStateReturn<T> {
    const [data, set] = useState<T>(defaultValue);
    const setData: WrappedSetter<T> = (next: T | ((prev: T) => T), isMounted: React.MutableRefObject<boolean>) => {
        if (isMounted.current) {
            set(next instanceof Function ? (prev) => next(prev) : next);
        }
    };
    return { data, setData };
}

export type StateSetterType<T> = (next: T | ((prev: T) => T)) => void;

export interface StateType<T> {
    readonly data: T;
    readonly set: StateSetterType<T>;
}

export function State<T> (defaultValue: T, isMounted: React.MutableRefObject<boolean>): StateType<T> {
    const [data, setValue] = useState<T>(defaultValue);

    const set: StateSetterType<T> = (next: T | ((prev: T) => T)) => {
        if (isMounted.current) {
            setValue(next instanceof Function ? (prev) => next(prev) : next);
        }
    };

    return { data, set };
}

import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Hidden,
    IconButton,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonIcon from '@mui/icons-material/Person';
import { isEmpty, isEqual, startCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PageTitle from '../components/coreLayout/PageTitle';
import BookingDetailSection, { ManageBookingFields } from '../components/customerPortal/ManageBookingInfo';
import IsMountedWrapper from '../utils/functions/isMountedWrapper';
import { BookingStore } from '../utils/stores/BookingStore';
import { baseProducts } from '../utils/stores/productCodes';
import { ProductsStore } from '../utils/stores/ProductsStore';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import SnackbarManager from '../utils/SnackbarManager';
import { convertFromRezdyBooking } from '../utils/functions/rezdy';
import { makeStyles } from 'tss-react/mui';

const confirmationStyles = makeStyles()((theme) => ({
    container: {
        [theme.breakpoints.only('sm')]: {
            maxWidth: 700
        },
        [theme.breakpoints.only('xs')]: {
            padding: '0 11px'
        }
    },
    searchBox: {
        padding: '0 20px',
        [theme.breakpoints.down('md')]: {
            padding: '15px 0'
        }
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
        marginTop: 10,
        [theme.breakpoints.only('xs')]: {
            padding: '0 11px'
        }
    },
    summarySection: {
        padding: 15,
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            padding: '0 15px'
        }
    },
    itemText: {
        fontSize: 17,
        lineHeight: '135%',
        fontWeight: 300
    },
    qtyText: {
        fontSize: 15,
        lineHeight: '110%',
        fontWeight: 300,
        marginBottom: 12,
        opacity: 0.65
    },
    divider: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D9DBE9',
        width: '100%',
        height: 0,
        marginTop: 20,
        [theme.breakpoints.down('lg')]: {
            marginTop: 5
        }
    }
}));

export default function ManageBooking() {
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const classes = confirmationStyles().classes;
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const isMounted = IsMountedWrapper();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedBooking, setSelectedBooking] = useState(0);

    const getQuery = () => {
        const queryString = location.search.replace('?', '');
        return queryString.split('&').reduce(
            (prev, cur) => {
                const [key, value] = cur.split('=');
                prev[key] = value;
                return prev;
            },
            { ref: '', name: '' }
        );
    };

    const { ref, name } = getQuery();
    const [bookingRef, setRef] = useState(ref);
    const [surname, setSurname] = useState(name);

    const toggleBooking = (direction: 'next' | 'prev') => {
        bookingController.rezdyResults.setData((prev) => {
            const tempArray = prev;
            tempArray.splice(selectedBooking, 1, bookingController.booking);
            return tempArray;
        }, isMounted);
        const newBookingNumber =
            direction === 'next'
                ? selectedBooking === bookingController.rezdyResults.data.length - 1
                    ? 0
                    : selectedBooking + 1
                : selectedBooking === 0
                  ? bookingController.rezdyResults.data.length - 1
                  : selectedBooking - 1;
        bookingController.rezdyResults.data[newBookingNumber].tour.productCode !==
            productController.product.productCode &&
            productController.setProduct(
                productController.products.find(
                    (p) => p.productCode === bookingController.rezdyResults.data[newBookingNumber].tour.productCode
                ),
                isMounted
            );
        bookingController.setBooking(bookingController.rezdyResults.data[newBookingNumber], isMounted);

        setSelectedBooking(newBookingNumber);
    };

    useEffect(() => {
        productController.products.length > 1 && ref && name && bookingController.findBooking(bookingRef, surname);
    }, [productController.isLoading]);

    useEffect(() => {
        bookingController.setIsLoading(false, isMounted);
        return () => {
            bookingController.clearController();
        };
    }, []);

    useEffect(() => {
        productController.setProduct(
            (productController.products || []).find(
                (p) => p.productCode === bookingController.rezdyResults.data?.[0]?.tour?.productCode
            ),
            isMounted
        );
    }, [bookingController.booking?.operatorRef]);

    const search = () => {
        bookingController.findBooking(bookingRef, surname);
        navigate(location.pathname + `?ref=${bookingRef}&name=${surname}`);
    };

    const checkPickUpValid = () => {
        const isValid =
            !isEmpty(bookingController.booking?.tour?.pickUpLocation) &&
            (productController.product?.pickupList || []).find(
                (pu) => pu.locationName === bookingController.booking.tour.pickUpLocation
            ) !== undefined;
        return isValid;
    };

    const checkChanges = () => {
        const oldBooking = convertFromRezdyBooking(productController.products, bookingController.originalBooking.data)[
            selectedBooking
        ];
        const newBooking = convertFromRezdyBooking(productController.products, bookingController.newBooking())[
            selectedBooking
        ];
        const participants = isEqual(oldBooking.participants, newBooking.participants);
        const pickUp = isEqual(oldBooking.tour.pickUpLocation, newBooking.tour.pickUpLocation);
        return participants && pickUp;
    };

    const saveUpdate = () => {
        if (bookingController.isParticipantInfoStepValid()) {
            if (checkPickUpValid) {
                if (checkChanges()) {
                    SnackbarManager.warning('No Changes Made');
                } else {
                    bookingController.updateBooking();
                    SnackbarManager.success('Booking Updated');
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                        navigate('/');
                    }, 1000);
                }
            } else {
                SnackbarManager.warning('Invalid Pick-Up');
            }
        } else {
            SnackbarManager.warning('Check Booking Info');
        }
    };

    return (
        <>
            <Container maxWidth="lg" style={{ padding: xsScreen && '0 6px' }}>
                <PageTitle title="Manage Booking" tagline="Manage your booking with GSL" />
            </Container>
            <Container maxWidth="md" className={classes.searchContainer}>
                <Grid container>
                    <Grid item xs={12} md={true}>
                        <TextField
                            label={'Booking No.'}
                            value={bookingRef}
                            variant="outlined"
                            fullWidth
                            disabled={bookingController.isLoading || productController.isLoading}
                            onBlur={(event) => setRef(event.target.value.trim())}
                            onChange={(event) => setRef(event.target.value.trim())}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    search();
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={true} className={classes.searchBox}>
                        <TextField
                            label={'Surname'}
                            value={surname}
                            variant="outlined"
                            fullWidth
                            disabled={bookingController.isLoading || productController.isLoading}
                            onBlur={(event) => setSurname(event.target.value.trim())}
                            onChange={(event) => setSurname(event.target.value.trim())}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    search();
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            style={{ height: 56 }}
                            disabled={bookingController.isLoading || productController.isLoading}
                            onClick={() => search()}
                        >
                            {bookingController.isLoading || productController.isLoading ? (
                                <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />
                            ) : (
                                'Search'
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            {bookingController.booking?.operatorRef ? (
                <Container maxWidth="md" className={classes.container}>
                    <Grid container justifyContent="center" style={{ paddingTop: 30, paddingBottom: 40 }}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Typography className={classes.itemText} style={{ opacity: 0.8 }}>
                                    Please review the booking information below, and make sure that all the required
                                    fields have been completed. This will ensure that we have all the information we
                                    need for your tour.
                                </Typography>
                                <Grid container direction="column">
                                    <Typography
                                        variant="h6"
                                        style={{
                                            paddingBottom: 10,
                                            paddingTop: 15,
                                            fontWeight: 400,
                                            opacity: 0.9,
                                            fontSize: smallScreen && 19
                                        }}
                                    >
                                        Your Details
                                    </Typography>
                                    <Typography
                                        component="div"
                                        className={classes.itemText}
                                        style={{ display: 'flex', opacity: 0.8, alignItems: 'center', marginBottom: 5 }}
                                    >
                                        <PersonIcon color="primary" />
                                        <Box style={{ marginLeft: 15 }} display="inline">
                                            {`${startCase(
                                                bookingController.rezdyResults.data?.[0]?.customer.firstName.toLowerCase()
                                            )} ${startCase(
                                                bookingController.rezdyResults.data?.[0]?.customer.lastName.toLowerCase()
                                            )}`}
                                        </Box>
                                    </Typography>
                                    <Typography
                                        component="div"
                                        className={classes.itemText}
                                        style={{ display: 'flex', opacity: 0.8, alignItems: 'center' }}
                                    >
                                        <ConfirmationNumberIcon color="primary" />
                                        <Box style={{ marginLeft: 15 }} display="inline">
                                            {bookingController.rezdyResults.data?.[0]?.operatorRef}
                                        </Box>
                                    </Typography>
                                    <Grid
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 20,
                                            backgroundColor: '#e432931f',
                                            padding: 10,
                                            borderRadius: 8,
                                            justifyContent: 'space-between'
                                        }}
                                        container
                                    >
                                        <Grid item>
                                            <IconButton
                                                aria-label="previous-booking"
                                                style={{
                                                    transform: 'rotate(180deg)',
                                                    display:
                                                        (bookingController.rezdyResults.data || []).length > 1
                                                            ? 'flex'
                                                            : 'none'
                                                }}
                                                onClick={() => toggleBooking('prev')}
                                                size="large"
                                            >
                                                <ArrowForward />
                                            </IconButton>
                                        </Grid>
                                        <Grid item style={{ alignItems: 'center', display: 'flex' }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontWeight: 400,
                                                    opacity: 0.9
                                                }}
                                            >
                                                {(bookingController.rezdyResults.data || []).length > 1
                                                    ? `Tour # ${selectedBooking + 1}`
                                                    : 'Your Booking'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                aria-label="next-booking"
                                                onClick={() => toggleBooking('next')}
                                                style={{
                                                    display:
                                                        (bookingController.rezdyResults.data || []).length > 1
                                                            ? 'flex'
                                                            : 'none'
                                                }}
                                                size="large"
                                            >
                                                <ArrowForward />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <PaymentDetails /> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Hidden only="xs">
                                <Grid container>
                                    <Grid item xs="auto">
                                        {productController.isLoading ? (
                                            <Box
                                                style={{
                                                    overflow: 'hidden',
                                                    borderRadius: 10,
                                                    height: 100,
                                                    width: 100,
                                                    marginRight: 15,
                                                    marginBottom: largeScreen ? 0 : 10
                                                }}
                                            >
                                                <Skeleton variant="rectangular" height="100%" width="100%" />
                                            </Box>
                                        ) : (
                                            <img
                                                src={
                                                    productController?.products.find(
                                                        (prod) =>
                                                            prod.productCode ===
                                                            bookingController.booking.tour.productCode
                                                    ).photos[0].thumbnailURL
                                                }
                                                alt="product booking thumbnail"
                                                style={{
                                                    objectFit: 'cover',
                                                    height: 100,
                                                    width: 100,
                                                    borderRadius: 10,
                                                    marginRight: 15,
                                                    marginBottom: largeScreen ? 0 : 10
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={true}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 23,
                                                        lineHeight: '115%',
                                                        paddingBottom: 10
                                                    }}
                                                >
                                                    {bookingController.booking.tour.productName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className={classes.itemText}
                                                    style={{
                                                        fontSize: 16,
                                                        marginBottom: xsScreen ? 10 : 0,
                                                        opacity: 0.9
                                                    }}
                                                >
                                                    {
                                                        baseProducts.find(
                                                            (prod) =>
                                                                prod.productCode ===
                                                                bookingController.booking.tour.productCode
                                                        ).tagline
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div className={classes.divider} />
                            </Hidden>
                            <BookingDetailSection booking={bookingController.booking} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <Typography variant="h6" style={{ fontWeight: 400, fontSize: 19, opacity: 0.9 }}>
                                    Required Fields
                                </Typography>
                            </Grid>
                            <ManageBookingFields />
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={12} sm="auto" style={{ marginTop: 15, minWidth: 200 }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        style={{
                                            height: 56,
                                            backgroundColor: theme.palette.success.main,
                                            color: 'white'
                                        }}
                                        disabled={bookingController.isLoading || productController.isLoading}
                                        onClick={() => saveUpdate()}
                                    >
                                        {bookingController.isLoading || productController.isLoading ? (
                                            <CircularProgress
                                                variant="indeterminate"
                                                disableShrink
                                                size={24}
                                                thickness={4}
                                            />
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                component="div"
                                style={{ padding: '20px 0 20px', fontWeight: 300, fontSize: 14, opacity: 0.8 }}
                            >
                                Your ticket is subject to our
                                <Box display="inline">
                                    {' '}
                                    <Link to={'/termsandconditions'}>terms &amp; conditions.</Link>
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Container maxWidth="lg">
                    <Grid
                        container
                        justifyContent="center"
                        direction="column"
                        alignItems="center"
                        style={{ height: '50vh', textAlign: 'center' }}
                    >
                        <Typography variant="h4" color="primary">
                            {bookingController.isLoading || productController.isLoading ? (
                                <Skeleton style={{ width: 250 }} />
                            ) : (
                                'No booking Information Found'
                            )}
                        </Typography>
                        <Typography color="primary">
                            {bookingController.isLoading || productController.isLoading ? (
                                <Skeleton style={{ width: 250 }} />
                            ) : (
                                'Search using the Reference Number and Customer Surname'
                            )}
                        </Typography>
                    </Grid>
                </Container>
            )}
        </>
    );
}

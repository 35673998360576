import { Container, Grid, Typography, Box } from '@mui/material';
import React from 'react';
import { Beach, DivingSnorkel, CameraOutline, TrophyOutline } from 'mdi-material-ui';
import { makeStyles } from 'tss-react/mui';

const iconStyles = makeStyles()((theme) => ({
    background: {
        width: '100%',
        backgroundColor: theme.palette.background.subtle
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 50,
        paddingBottom: 30,
        [theme.breakpoints.only('sm')]: {
            paddingTop: 60
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 30
        }
    },
    iconOuter: {
        width: '5.2rem',
        height: '5.2rem',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        }
    },
    iconSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    icon: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='4' stroke-dasharray='4%25%2c 5%25' stroke-dashoffset='85' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 100px;`,
        backgroundColor: 'inherit',
        width: '4.1rem',
        height: '4.1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderCollapse: 'collapse'
    },
    text: {
        fontWeight: 400,
        color: theme.palette.text.soft,
        margin: '20px 25px',
        textAlign: 'center',
        fontSize: '1.2rem',
        lineHeight: '115%',
        letterSpacing: '.0035em',
        [theme.breakpoints.down('md')]: {
            maxWidth: 220
        }
    }
}));

export default function IconSection() {
    const classes = iconStyles().classes;
    const iconColor = 'white';
    const iconSize = '2.2rem';

    const sections = [
        {
            text: 'Ask us about Whitehaven Beach',
            icon: (
                <Beach
                    style={{
                        fontSize: iconSize,
                        color: iconColor
                    }}
                />
            )
        },
        {
            text: 'Snorkel in the Whitsunday Islands',
            icon: (
                <DivingSnorkel
                    style={{
                        fontSize: iconSize,
                        color: iconColor
                    }}
                />
            )
        },
        {
            text: 'Capture Unique World Heritage Sites',
            icon: (
                <CameraOutline
                    style={{
                        fontSize: iconSize,
                        color: iconColor
                    }}
                />
            )
        },
        {
            text: 'Book with award winning operators',
            icon: (
                <TrophyOutline
                    style={{
                        fontSize: iconSize,
                        color: iconColor
                    }}
                />
            )
        }
    ];

    return (
        <div className={classes.background}>
            <Container maxWidth="lg">
                <Grid container className={classes.container}>
                    {sections.map((s, i) => (
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={`icon${i + 1}`}
                        >
                            <Grid item className={classes.iconOuter}>
                                <Grid item className={classes.iconSection}>
                                    <Box className={classes.icon}>{s.icon}</Box>
                                </Grid>
                            </Grid>
                            <Typography className={classes.text}>{s.text}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}
